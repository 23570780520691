/* eslint-disable react/jsx-props-no-spreading */
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LinkComponent from 'next/link';
import React, {
  FC,
  forwardRef,
  Ref,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  RefObject,
} from 'react';
import cn from 'classnames';
import qs from 'query-string';

import styles from 'src/styles/_commonClasses.module.scss';
import { PossibleStringOrNumber, UrlWithQuery } from 'types/objectTypes';
import { renderOptions } from 'lib/richTextOptions';
import { IntersectionObserverWrapper } from 'lib/intersectionObserver';
import {
  EXTERNAL_WEBP_IMAGE,
  MAX_CONTENTFUL_IMAGE_SIDE_SIZE,
  WEBP_FORMAT,
  WEBP_TYPE,
} from 'constants/constants';
import { useCommonDataButtonsProps } from 'lib/shared.hook';
import { Asset } from 'src/__generated__/graphqlTypes';
import { CommonSize, ImageFitMode } from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import {
  PsychicRateInterface,
  WebpImageInterface,
  ImagesWithWidthLimitInterface,
  DefaultWebpSourcesInterface,
  CommonTitleInterface,
  CommonRichTextInterface,
  DataButtonInterface,
  DataLinkInterface,
  ValidationMarkInterface,
  SliderArrowInterface,
  MultiSliderArrowInterface,
  IconInterface,
  RichTextInterface,
  SliderArrowImageInterface,
} from 'components/Shared/declarations';
import { getAppropriatePathForResource } from 'lib/tag.service';
import { useRaiseTrackLinkEvent } from 'lib/external/segment';

const constructNewQueryWithHWProps = <T, >(
  additionalProps: Record<string, T>,
  w?: T,
  h?: T,
) => ({ ...additionalProps, h, w });

const multiplyOrOmitQueryParam = (queryParam: number, multiplier: number = 2) => (queryParam
  ? queryParam * multiplier
  : undefined);

const getUrlWithTwoPixelDensities = (url: string = '', query: any) => {
  const { h, w } = query;
  const singlePixelDensityQuery = { ...query };
  const doublePixelDensityQuery = {
    ...singlePixelDensityQuery,
    w: multiplyOrOmitQueryParam(w),
    h: multiplyOrOmitQueryParam(h),
  };
  const singlePixelDensityUrl = qs.stringifyUrl({ url, query: singlePixelDensityQuery });

  if ((doublePixelDensityQuery.w < MAX_CONTENTFUL_IMAGE_SIDE_SIZE || !w)
    && (doublePixelDensityQuery.h < MAX_CONTENTFUL_IMAGE_SIDE_SIZE || !h)) {
    const doublePixelDensityUrl = qs.stringifyUrl({ url, query: doublePixelDensityQuery });

    return `
    ${singlePixelDensityUrl} 1x,
    ${doublePixelDensityUrl} 2x
  `;
  }

  const oneAndHalfDensity = 1.5;
  const oneAndHalfPixelDensityQuery = {
    ...singlePixelDensityQuery,
    w: multiplyOrOmitQueryParam(w, oneAndHalfDensity),
    h: multiplyOrOmitQueryParam(h, oneAndHalfDensity),
  };

  if (oneAndHalfPixelDensityQuery.w < MAX_CONTENTFUL_IMAGE_SIDE_SIZE
      && oneAndHalfPixelDensityQuery.h < MAX_CONTENTFUL_IMAGE_SIDE_SIZE) {
    const doublePixelDensityUrl = qs.stringifyUrl({ url, query: oneAndHalfPixelDensityQuery });

    return `
      ${singlePixelDensityUrl} 1x,
      ${doublePixelDensityUrl} ${oneAndHalfDensity}x
    `;
  }

  return `${singlePixelDensityUrl} 1x`;
};

const buildFinalUrlWithParams = (
  urlWithQuery: UrlWithQuery,
  image: Asset,
  isAdditionalPropsExists: boolean,
) => {
  const { url = '', query } = urlWithQuery;
  const { width: imageWidth, height: imageHeight } = image;

  if (isAdditionalPropsExists) {
    const { w: width, h: height } = query || {};

    if (!width && !height) {
      if (!imageWidth && !imageHeight) {
        return qs.stringifyUrl({ url, query });
      }

      const newQuery = constructNewQueryWithHWProps<PossibleStringOrNumber>(
        query,
        imageWidth!,
        imageHeight!,
      );

      return getUrlWithTwoPixelDensities(url, newQuery);
    }

    const newQuery = constructNewQueryWithHWProps<PossibleStringOrNumber>(query, width!, height!);

    return getUrlWithTwoPixelDensities(url, newQuery);
  }

  const newQuery = constructNewQueryWithHWProps<PossibleStringOrNumber>(
    { fit: ImageFitMode.SCALE, fm: query.fm },
    imageWidth!,
    imageHeight!,
  );

  return getUrlWithTwoPixelDensities(url, newQuery);
};

const ImagesWithWidthLimit: React.FC<ImagesWithWidthLimitInterface> = ({
  url = '',
  widthLimit,
  contentType,
  isLazy = false,
}) => {
  const srcParamName = isLazy
    ? 'data-srcset'
    : 'srcSet';

  return (
    <>
      {widthLimit
        ?.map(({
          width: w,
          height: h,
          media,
          type,
          fit,
          isMinWidth,
        }, i) => {
          if (!w) {
            return null;
          }

          const imageType = type || contentType;
          const widthThreshold = `${isMinWidth ? 'min' : 'max'}-width`;
          const query = { fit, h, w };
          const applyUrlWithParams = (fm?: string) => buildFinalUrlWithParams(
            { url, query: { ...query, fm } },
            {} as Asset,
            true,
          );
          const defaultLinkWithParams = applyUrlWithParams();
          const mediaValue = (media) ? `(${widthThreshold}: ${media})` : undefined;

          return (
            <React.Fragment key={`${defaultLinkWithParams}${i.toString()}`}>
              <source
                {...{ [srcParamName]: applyUrlWithParams(WEBP_FORMAT) }}
                media={mediaValue}
                type={WEBP_TYPE}
              />
              <source
                {...{ [srcParamName]: defaultLinkWithParams }}
                media={mediaValue}
                type={imageType}
              />
            </React.Fragment>
          );
        })}
    </>
  );
};

const DefaultWebpSources: React.FC<DefaultWebpSourcesInterface> = ({
  url = '',
  image = {},
  query,
  isLazy = false,
}) => {
  const isAdditionalPropsExists = !!(query && Object.keys(query).length > 0);
  const srcParamName = isLazy
    ? 'data-srcset'
    : 'srcSet';
  const applyUrlWithParams = (fm?: string) => buildFinalUrlWithParams(
    { url, query: { ...query, fm } },
    image as Asset,
    isAdditionalPropsExists,
  );

  return (
    <>
      <source
        {...{ [srcParamName]: applyUrlWithParams(WEBP_FORMAT) }}
        type={WEBP_TYPE}
      />
      <source
        {...{ [srcParamName]: applyUrlWithParams() }}
        type={image?.contentType || ''}
      />
    </>
  );
};

export const WebpImage = forwardRef<HTMLImageElement, WebpImageInterface>(({
  src,
  alt,
  image: imageOrString,
  widthLimit,
  additionalProps,
  pictureClassName,
  disableDefaultSource = false,
  ...rest
}, ref) => {
  const isExternalImage = imageOrString === EXTERNAL_WEBP_IMAGE;
  const applyDefaultImage = (url: string = '', alt: string = '') => (
    <img
      ref={ref}
      src={url}
      alt={alt}
      {...rest}
    />
  );

  if (isExternalImage) {
    return applyDefaultImage(src, alt);
  }

  const image = imageOrString as Asset;
  const imageUrl = src || image?.url!;
  const altText = alt || image?.title!;

  return (
    <picture className={pictureClassName}>
      <ImagesWithWidthLimit
        url={imageUrl}
        widthLimit={widthLimit}
        contentType={image?.contentType!}
      />
      {!disableDefaultSource && (
        <DefaultWebpSources
          url={imageUrl}
          image={image}
          query={additionalProps}
        />
      )}
      {applyDefaultImage(imageUrl, altText)}
    </picture>
  );
});

export const LazyWebpImage = forwardRef<HTMLImageElement, WebpImageInterface>(({
  src,
  alt,
  image: imageOrString,
  widthLimit,
  additionalProps,
  pictureClassName,
  loadingThreshold,
  onLoad,
  ...rest
}, ref) => {
  const localRef = useRef<HTMLImageElement>(null);
  const [isImageLoaded, setImageloadingState] = useState<boolean>(false);
  const loadingCallback = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    onLoad?.(e);
    setImageloadingState(true);
  }, [setImageloadingState, onLoad]);
  const isExternalImage = imageOrString === EXTERNAL_WEBP_IMAGE;
  const image = imageOrString as Asset;
  const imageUrl = src || (!isExternalImage ? image?.url! : '');
  const altText = alt || (!isExternalImage ? image?.title! : '');
  const applyDefaultImage = (url: string = '', alt: string = '') => (
    <img
      ref={ref || localRef}
      data-src={url}
      alt={alt}
      {...rest}
      onLoad={loadingCallback}
      className={cn(
        rest.className,
        !isImageLoaded && styles.loadImagesAnimation,
      )}
    />
  );

  useEffect(() => {
    const imageRef = ref as React.RefObject<HTMLImageElement> || localRef;
    const pictureRef = imageRef.current?.parentElement;

    if (!pictureRef) {
      return;
    }

    const observer = IntersectionObserverWrapper.getInstance(
      undefined,
      { rootMargin: '100px', threshold: loadingThreshold },
      { callback: loadingCallback as any, key: imageUrl || '' },
    );

    if (imageUrl) {
      observer?.observe(pictureRef);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (pictureRef && observer) observer.unobserve(pictureRef);
    };
  }, [imageUrl, ref, localRef, loadingCallback]);

  if (isExternalImage) {
    return applyDefaultImage(src, alt);
  }

  return (
    <picture className={pictureClassName}>
      <ImagesWithWidthLimit
        url={imageUrl}
        widthLimit={widthLimit}
        contentType={image?.contentType!}
        isLazy
      />
      <DefaultWebpSources
        url={imageUrl}
        image={image}
        query={additionalProps}
        isLazy
      />
      {applyDefaultImage(imageUrl, altText)}
    </picture>
  );
});

export const CommonTitle: React.FC<CommonTitleInterface> = ({
  title,
  ...rest
}) => (
  <span {...rest}>{ title }</span>
);

export const H1: React.FC<CommonTitleInterface> = ({
  title,
  ...rest
}) => (
  <h1 {...rest}>{ title }</h1>
);

export const H2: React.FC<CommonTitleInterface> = ({
  title,
  ...rest
}) => (
  <h2 {...rest}>{ title }</h2>
);

export const H3: React.FC<CommonTitleInterface> = ({
  title,
  ...rest
}) => (
  <h3 {...rest}>{ title }</h3>
);

export const H4: React.FC<CommonTitleInterface> = ({
  title,
  ...rest
}) => (
  <h4 {...rest}>{ title }</h4>
);

export const H5: React.FC<CommonTitleInterface> = ({
  title,
  ...rest
}) => (
  <h5 {...rest}>{ title }</h5>
);

export const H6: React.FC<CommonTitleInterface> = ({
  title,
  ...rest
}) => (
  <h6 {...rest}>{ title }</h6>
);

export const CommonRichText: React.FC<CommonRichTextInterface> = ({
  content,
  parsersConfig,
}) => {
  if (!content) {
    return null;
  }

  const { json, links } = content;

  return (
    <>
      {documentToReactComponents(json, renderOptions(links, parsersConfig))}
    </>
  );
};

export const DataButton = forwardRef<HTMLButtonElement, DataButtonInterface>(({
  children,
  link,
  gaData,
  ...rest
}, ref) => {
  const { styles, parsedGaProperties } = useCommonDataButtonsProps(gaData, link);

  return (
    <button
      type="button"
      style={styles}
      {...parsedGaProperties}
      {...rest}
      ref={ref}
    >
      {children}
    </button>
  );
});

export const DataLink = forwardRef<HTMLAnchorElement, DataLinkInterface>(({
  children,
  href,
  link,
  gaData,
  segmentData,
  ...rest
}, ref) => {
  const { styles, parsedGaProperties } = useCommonDataButtonsProps(gaData, link);
  const { prefetch, scroll, ...attributes } = rest;
  const path = href || link?.src || '#';
  const localRef = useRef<HTMLLinkElement>(null);
  const finalRef = ref as RefObject<HTMLLinkElement> || localRef;

  useRaiseTrackLinkEvent(finalRef, segmentData);

  if (path[path.length - 1] === '/') {
    // @ts-ignore
    attributes.href = path;
  }

  const { isMatched, path: calculatedPath } = getAppropriatePathForResource(path);

  if (isMatched) {
    return (
      <a
        style={styles}
        {...parsedGaProperties}
        title={link?.alt}
        {...attributes}
        href={calculatedPath}
        ref={finalRef}
      >
        {children}
      </a>
    );
  }

  return (
    <LinkComponent
      href={calculatedPath}
      prefetch={prefetch}
      scroll={scroll}
    >
      <a
        style={styles}
        {...parsedGaProperties}
        title={link?.alt}
        {...attributes}
        ref={ref}
      >
        {children}
      </a>
    </LinkComponent>
  );
});

export const PsychicRate: React.FC<PsychicRateInterface> = ({
  pricePerMinute,
  priceWithDiscountPerMinute,
  classNames,
}) => {
  const commonPriceAndPriceWithDiscount = (
    <>
      <s className={classNames.commonWithDiscount}>{pricePerMinute}</s>
      <span className={classNames.discount}>{priceWithDiscountPerMinute}</span>
    </>
  );
  const commonPrice = <span className={classNames.common}>{priceWithDiscountPerMinute}</span>;

  return (
    <div className={classNames.wrapper}>
      { (priceWithDiscountPerMinute !== pricePerMinute)
        ? commonPriceAndPriceWithDiscount
        : commonPrice}
    </div>
  );
};

export const ValidationMark: React.FC<ValidationMarkInterface> = ({
  isValid,
  size = CommonSize.MEDIUM,
}) => {
  const markType = isValid ? 'Check' : 'Error';
  const sizeType = capitalizeFirstLetter(size);

  return (
    <span className={cn(
      styles.mark,
      styles[`mark${sizeType}`],
      styles[`mark${sizeType}${markType}Background`],
    )}
    >
      <i className={styles[`mark${sizeType}${markType}`]} />
    </span>
  );
};

const SliderArrowImage: FC<SliderArrowImageInterface> = ({
  href,
  image,
  width,
  height,
  altImage,
  ...rest
}) => {
  if (!href && !image) {
    return <>{altImage}</>;
  }

  const componentImage = href ? EXTERNAL_WEBP_IMAGE : image!;

  return (
    <WebpImage
      image={componentImage}
      src={href}
      additionalProps={{ h: height!, w: width!, fit: ImageFitMode.SCALE }}
      height={height ? `${height}px` : undefined}
      width={width ? `${width}px` : undefined}
      {...rest}
      style={{ ...rest.style || {}, pointerEvents: 'none' }}
    />
  );
};

export const SliderArrow: FC<SliderArrowInterface> = ({
  arrow,
  altImage,
  hasNextElement,
  label,
  defaultControlClass,
  className,
  height = 20,
  width = 9,
  href,
  clickHandler,
  ...rest
}) => {
  if (!arrow?.image && hasNextElement) {
    return (
      <DataButton
        link={arrow}
        aria-label={label}
        className={cn('control-arrow', defaultControlClass)}
        onClick={clickHandler}
        {...rest}
      />
    );
  }

  const { image } = arrow;

  return (
    <DataButton
      link={arrow}
      title={label}
      aria-label={label}
      className={className}
      onClick={clickHandler}
      {...rest}
    >
      <SliderArrowImage
        image={image}
        altImage={altImage}
        href={href}
        height={height}
        width={width}
      />
    </DataButton>
  );
};

export const MultiSliderArrow: FC<MultiSliderArrowInterface> = ({
  arrow,
  href,
  altImage,
  className,
  height,
  width,
  clickHandler,
  onClick,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  carouselState,
  ...rest
}) => {
  const handleOnClicks = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    onClick?.();
    clickHandler?.(e);
  }, [clickHandler, onClick]);

  if (!arrow?.image) {
    return (
      <button
        type="button"
        {...rest}
      />
    );
  }

  const { image } = arrow;

  return (
    <DataButton
      link={arrow}
      {...rest}
      ref={rest.ref as Ref<HTMLButtonElement>}
      onClick={handleOnClicks}
      className={className}
    >
      <SliderArrowImage
        image={image}
        altImage={altImage}
        href={href}
        height={height}
        width={width}
      />
    </DataButton>
  );
};

export const Icon: FC<IconInterface> = ({ image, className }) => {
  if (!image) {
    return null;
  }

  return (
    <i
      className={className}
      style={{ backgroundImage: `url(${image.url!})` }}
    />
  );
};

export const Content: FC<RichTextInterface> = ({ richText, config }) => (
  <CommonRichText
    content={richText}
    parsersConfig={config}
  />
);

export const Title = Content;
