import { FC } from 'react';

import styles from 'components/Sections/ExponeaPsychicProfile/ExponeaPsychicProfile.module.scss';
import {
  Icon,
  PsychicRate,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { getStatus } from 'lib/psychic.service';
import { RatePerMinuetType } from 'types/objectTypes';
import {
  EstimationComponentInterface,
  PriceComponentInterface,
  ProfileInfoInterface,
  QueueComponentInterface,
  RatingComponentInterface,
  StatusComponentInterface,
} from 'components/Sections/ExponeaPsychicProfile/declarations';
import { PsychicStatusIndicator, PsychicStatusText } from 'entities/PsychicStatus';

/* Nested component */
const StatusComponent: FC<StatusComponentInterface> = ({
  psychic,
  chatStatus,
  phoneStatus,
  statusText,
  psychicFrame,
}) => {
  const status = getStatus({ chatStatus, phoneStatus });

  return (
    <div className={styles.status}>
      <div className={styles.statusIndicator}>
        <PsychicStatusIndicator
          status={status}
          className={styles.statusIndicator}
        />
        <span className={styles.statusIndicatorText}>{statusText}</span>
      </div>
      <PsychicStatusText
        status={status}
        psychic={psychic}
        psychicFrame={psychicFrame}
        className={styles.statusText}
        wrapperClass={styles.statusTextWrapper}
      />
    </div>
  );
};

/* Nested component */
const RatingComponent: FC<RatingComponentInterface> = ({ overallScore, starImage, responses }) => (
  <div className={styles.rating}>
    {overallScore}
    {starImage?.url && (
      <Icon
        image={starImage}
        className={styles.ratingIcon}
      />
    )}
    {`(${responses})`}
  </div>
);

/* Nested component */
const PriceComponent: FC<PriceComponentInterface> = ({ ratePerMinute, ratePerMinuteText }) => {
  const priceStyles = {
    wrapper: styles.price,
    common: styles.priceCommon,
    commonWithDiscount: styles.priceCrossed,
    discount: styles.priceDiscount,
  };

  return (
    <div className={styles.priceWrapper}>
      <PsychicRate
        pricePerMinute={ratePerMinute.price}
        priceWithDiscountPerMinute={ratePerMinute.discount}
        classNames={priceStyles}
      />
      <span className={styles.priceWrapperText}>{ratePerMinuteText}</span>
    </div>
  );
};

/* Nested component */
const QueueComponent: FC<QueueComponentInterface> = ({ queue, peopleInQueue }) => {
  if (!queue) {
    return null;
  }

  const { image, alt, title } = queue;

  return (
    <div className={styles.queue}>
      <span className={styles.queueTitle}>{title}</span>
      {image?.url && (
        <div className={styles.queueIcons}>
          {new Array(6).fill('').map(() => (
            <WebpImage
              image={EXTERNAL_WEBP_IMAGE}
              src={image.url || ''}
              className={styles.queueIcon}
            />
          ))}
        </div>
      )}
      <strong className={styles.queueDescription}>
        {peopleInQueue}
        {' '}
        {alt}
      </strong>
    </div>
  );
};

/* Nested component */
const EstimationComponent: FC<EstimationComponentInterface> = ({
  estimatedWaiting,
  estimatedWaitTime,
}) => (
  <div className={styles.estimation}>
    <span className={styles.estimationTitle}>{estimatedWaiting}</span>
    <strong className={styles.estimationValue}>
      {estimatedWaitTime}
      m
    </strong>
  </div>
);

/* Main component */
const ProfileInfo: FC<ProfileInfoInterface> = ({ psychic, psychicFrame }) => {
  const {
    chatStatus,
    phoneStatus,
    customerPrice,
    basePrice,
    overallScore,
    responses,
    peopleInQueue,
    estimatedWaitTime,
  } = psychic;
  const ratePerMinute: RatePerMinuetType = {
    discount: customerPrice,
    price: basePrice,
  };

  return (
    <div className={styles.profileInfo}>
      <WebpImage
        image={EXTERNAL_WEBP_IMAGE}
        src={psychic.images[3] || psychicFrame.avatar?.url || ''}
        className={styles.profileInfoImage}
      />
      <div className={styles.profileInfoContent}>
        <StatusComponent
          psychic={psychic}
          psychicFrame={psychicFrame}
          phoneStatus={phoneStatus}
          chatStatus={chatStatus}
          statusText={psychicFrame.status}
        />
        <RatingComponent
          overallScore={overallScore}
          starImage={psychicFrame.starImage}
          responses={responses}
        />
        <PriceComponent
          ratePerMinute={ratePerMinute}
          ratePerMinuteText={psychicFrame.ratePerMinute}
        />
        <QueueComponent
          queue={psychicFrame.queue}
          peopleInQueue={peopleInQueue}
        />
        <EstimationComponent
          estimatedWaiting={psychicFrame.estimatedWaiting}
          estimatedWaitTime={estimatedWaitTime}
        />
      </div>
    </div>
  );
};

export default ProfileInfo;
