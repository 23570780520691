import {
  CONTACT_US_SLUG,
  HOMEPAGE_SLUG,
  EC_HOMEPAGE_SLUG,
  ABOUT_SLUG,
} from 'constants/constants';

import { SIDE_SIZE_OF_CHAT_BUBBLE } from './declarations';

const allowedPages = {
  [CONTACT_US_SLUG]: true,
  '/': true,
  [HOMEPAGE_SLUG]: true,
  [EC_HOMEPAGE_SLUG]: true,
  [ABOUT_SLUG]: true,
};

export const checkShouldPageHaveAgentChat = (slug: string) => allowedPages[slug];

export class AgentChatIframe {
  private static iframe: HTMLIFrameElement | null;

  static setIframe(iframe: HTMLIFrameElement | null) {
    this.iframe = iframe;
  }

  static isOpened() {
    if (!this.isSet()) {
      return false;
    }

    const height = this.iframe!.clientHeight || 0;
    const width = this.iframe!.clientWidth || 0;

    return height > SIDE_SIZE_OF_CHAT_BUBBLE && width > SIDE_SIZE_OF_CHAT_BUBBLE;
  }

  static isSet() {
    return Boolean(this.iframe);
  }
}
