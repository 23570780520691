import { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import styles from './PsychicWithTestimonial.module.scss';
import PsychicWithTestimonial from './PsychicWithTestimonial';

import { ICarousel } from '../config/declarations';
import {
  getLeftArrowRenderer,
  getRightArrowRenderer,
  renderIndicator,
} from '../lib/renderers';

const CustomCarousel: FC<ICarousel> = ({
  psychics,
  psychicFrame,
  leftArrow,
  rightArrow,
  ...rest
}) => (
  <Carousel
    className={styles.carousel}
    renderArrowNext={getRightArrowRenderer(rightArrow)}
    renderArrowPrev={getLeftArrowRenderer(leftArrow)}
    renderIndicator={renderIndicator}
    infiniteLoop
    showThumbs={false}
    showStatus={false}
    {...rest}
  >
    {psychics
      ?.filter(({ psychic }) => Boolean(psychic.featuredTestimonialData))
      ?.map(({ psychic }) => (
        <PsychicWithTestimonial
          key={psychic.extId}
          psychicWithTestimonial={psychic}
          starImage={psychicFrame.starImage!}
          profileLink={psychicFrame.profileButton!}
        />
      ))}
  </Carousel>
);

export default CustomCarousel;
