import { FC, Fragment } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import type { Block } from 'src/__generated__/graphqlTypes';
import styles from 'src/components/Sections/AboutHeroImage/AboutHeroImage.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import type { RichTextParsersConfig } from 'types/objectTypes';
import type { SectionComponentInterface } from 'types/componentTypes';
import { CommonRichText as Content, DataLink } from 'components/Shared/SharedComponents';
import { mapBlocksHeroImage } from 'lib/richTextMappers';
import { capitalizeFirstLetter } from 'lib/text.service';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.EMBEDDED_ASSET]: {
    classNames: styles.heroImage,
    mapper: mapBlocksHeroImage,
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.heroParagraphText,
  },
  [BLOCKS.HEADING_2]: {
    classNames: styles.heroParagraphTitle,
  },
  [BLOCKS.HEADING_1]: {
    classNames: styles.heroTitle,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
};

/* Nested component */
const Title = Content;

/* Main component */
const AboutHeroImage: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const { maxWidth = 'local', verticalPadding } = extraData || {};
  const buildComponents = () => blocks
    .map((block: Block) => {
      const { content, link, entryName, richTitle } = block;

      if (!content) {
        return null;
      }

      return (
        <Fragment key={entryName}>
          <Title
            content={richTitle}
            parsersConfig={parsersConfig}
          />
          <Content
            content={content}
            parsersConfig={parsersConfig}
          />
          {link && (
            <DataLink
              link={link}
              href={link.src!}
              className={styles.heroButton}
            >
              {link.title}
            </DataLink>
          )}
        </Fragment>
      );
    });

  return (
    <section
      className={cn(
        styles.wrapper,
        { [classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`]]: Boolean(verticalPadding) },
      )}
      style={{ background: bgColor }}
    >
      <div className={cn(
        styles.hero,
        styles[`heroWidth${capitalizeFirstLetter(maxWidth)}`],
      )}
      >
        {buildComponents()}
      </div>
    </section>
  );
};

export default AboutHeroImage;
