import { useEffect } from 'react';

import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { getSortIdQueryParam } from 'src/shared/lib/url';
import { HOMEPAGE_SLUG, LOWERCASE_SORT_ID_KEY } from 'constants/constants';

import { getSortIdCookie } from '.';

export const useSortIdQueryParam = (slug: string) => {
  const router = useCustomRouter();
  const { query } = router;
  const sortingIdQueryParam = getSortIdQueryParam(query);

  useEffect(() => {
    const sortingIdCookie = getSortIdCookie();

    if (slug === HOMEPAGE_SLUG && !sortingIdQueryParam && sortingIdCookie) {
      const cookieForQuery = sortingIdCookie.replace(/^"|"$/g, '');
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set(LOWERCASE_SORT_ID_KEY, cookieForQuery);
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      router.replace(newUrl);
    }
  }, [sortingIdQueryParam, router, slug]);

  return sortingIdQueryParam || '';
};
