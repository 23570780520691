import {
  FC,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';

import { Block, Link } from 'src/__generated__/graphqlTypes';
import { WebpImage } from 'components/Shared/SharedComponents';
import styles from 'components/Sections/ContactUsGetInTouch/PhoneSupport/PhoneSupport.module.scss';
import { PhoneSupportSelect } from 'components/Sections/ContactUsGetInTouch/PhoneSupport';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import {
  PhoneSupportInterface,
  CountriesSelectInterface,
  DisplayedCountryInterface,
} from 'components/Sections/ContactUsGetInTouch/PhoneSupport/declarations';
import { SmsButtonSection } from 'entities/SmsButtonSection';
import { ViewerDevice } from 'constants/enums';

const PhoneNumber = dynamic(() => import('components/Sections/ContactUsGetInTouch/PhoneSupport/PhoneNumber'), { ssr: false });

/* Nested component */
const CountriesSelect: FC<CountriesSelectInterface> = ({
  input,
  countries,
  setCurrentCountry,
}) => {
  if (!input) {
    return null;
  }

  const { label, placeholder, sys } = input;

  return (
    <div className={styles.phoneSupportCountries}>
      <h3 className={styles.phoneSupportCountriesTitle}>{label}</h3>
      <PhoneSupportSelect
        id={sys.id}
        options={countries}
        placeholder={placeholder!}
        signImageSize={0}
        setSelectedItem={setCurrentCountry}
      />
    </div>
  );
};

/* Nested component */
const DisplayedCountry: FC<DisplayedCountryInterface> = ({
  currentCountry,
}) => {
  if (!currentCountry) {
    return null;
  }

  const { contentTypesCollection, link } = currentCountry;
  const phones = contentTypesCollection?.items as Array<Link> | undefined || [];

  if (!link) {
    return null;
  }

  const { image, title: country } = link;

  return (
    <div className={styles.phoneSupportCountry}>
      <div className={styles.phoneSupportCountryImage}>
        {image && (
          <WebpImage
            image={EXTERNAL_WEBP_IMAGE}
            alt={image.title!}
            src={image.url!}
            className={styles.phoneSupportCountryImageIcon}
          />
        )}
        <span className={styles.phoneSupportCountryImageTitle}>
          {country}
        </span>
      </div>
      {phones.map((link) => (
        <p
          key={link.entryName}
          className={cn(
            styles.phoneSupportCountryPhone,
            styles.phoneSupportCountryPhoneMarginTop,
          )}
        >
          <span className={styles.phoneSupportCountryPhoneText}>{link.title}</span>
          <PhoneNumber
            link={link}
            country={country}
          />
        </p>
      ))}
    </div>
  );
};

/* Main component */
const PhoneSupport: FC<PhoneSupportInterface> = ({
  title,
  input,
  countries,
  smsButton,
  viewerDevice,
  firstCountry,
}) => {
  const [currentCountry, setCurrentCountry] = useState<Block>(firstCountry);
  const [currentCountries, setCurrentCountries] = useState<Array<Block>>(countries);
  const shouldShowSmsButton = viewerDevice === ViewerDevice.MOBILE
    && currentCountry.slug === 'usa';

  useEffect(() => {
    setCurrentCountries((countries
      .filter((country) => country.entryName !== currentCountry.entryName)));
  }, [currentCountry, countries]);

  return (
    <div className={styles.phoneSupport}>
      <h2 className={styles.phoneSupportTitle}>{title}</h2>
      <DisplayedCountry currentCountry={currentCountry} />
      {shouldShowSmsButton && (
        <SmsButtonSection
          block={smsButton}
          headingClassName={cn(styles.phoneSupportCountriesTitle, styles.smsTitle)}
          linkClassName={styles.smsButton}
        />
      )}
      <CountriesSelect
        countries={currentCountries}
        input={input}
        setCurrentCountry={setCurrentCountry}
      />
    </div>
  );
};
export default PhoneSupport;
