/* eslint-disable react/jsx-props-no-spreading */
import {
  FC,
  FocusEvent,
  MouseEvent,
} from 'react';
import cn from 'classnames';
import { useCombobox } from 'downshift';

import classes from 'src/styles/_commonClasses.module.scss';
import { getSelectItemId } from 'lib/sharedMethods.service';

import styles from './Form.module.scss';
import InputAutocomplete from './Components/InputAutocomplete';
import ErrorMessage from './Components/ErrorMessage';

import { IFeedbackFormAutocomplete } from '../config/declarations';

const FeedbackFormAutocomplete: FC<IFeedbackFormAutocomplete> = ({
  title,
  name,
  selectOptions,
  className,
  inputData,
  onChange,
  onBlur,
  setInputValue,
  onClick,
}) => {
  const {
    isOpen,
    highlightedIndex,
    openMenu,
    getMenuProps,
    getInputProps,
    getToggleButtonProps,
    getItemProps,
  } = useCombobox({
    onInputValueChange({ selectedItem }) {
      setInputValue(selectedItem);
    },
    items: selectOptions,
    itemToString(item) {
      return item ? item.text! : '';
    },
  });
  const { isValid, error, sysId } = inputData;
  const isNotValid = isValid === false;

  const inputWrapperStyles = cn(
    styles.formFieldsetDivInput,
    styles.formDropdownButtonPaddingUnset,
    {
      [styles.formFieldsetInputFocused]: isOpen,
      [styles.formDropdownButtonActive]: isOpen && selectOptions.length,
      [styles.formFieldsetDivInputSuccess]: isValid,
      [styles.formFieldsetDivInputError]: isNotValid,
    },
  );

  const inputProps = getInputProps({
    ...getSelectItemId(sysId, 'input'),
  });
  const toggleButtonProps = getToggleButtonProps({
    ...getSelectItemId(sysId, 'button'),
  });
  const menuProps = getMenuProps(getSelectItemId(sysId, 'ul'));

  const handleClick = (e: MouseEvent<HTMLButtonElement | HTMLInputElement>) => {
    if (selectOptions.length === 0 && onClick) {
      onClick(e);
    }
  };

  const openInputMenu = () => {
    if (!isOpen) {
      openMenu();
    }
  };

  const onUlBlur = (e: FocusEvent<HTMLUListElement>) => {
    onBlur(e);
    menuProps?.onBlur?.(e);
  };

  return (
    <div className={cn(styles.formDropdown, className)}>
      <InputAutocomplete
        className={inputWrapperStyles}
        isOpen={isOpen}
        buttonProps={toggleButtonProps}
        props={inputProps}
        openMenu={openInputMenu}
        onClick={handleClick}
        onChange={onChange}
        name={name}
        title={title}
      />
      <ErrorMessage error={error} isInvalid={isNotValid} />
      <ul
        className={cn(
          styles.formDropdownMenu,
          { [cn(classes.visibilityHidden, classes.zeroHeight)]: !isOpen || !selectOptions.length },
        )}
        {...menuProps}
        onBlur={onUlBlur}
      >
        {selectOptions.map((item, index) => {
          const { entryName, text } = item;
          const itemProps = getItemProps({ item, index, ...getSelectItemId(sysId, 'li') });

          return (
            <li
              key={entryName!}
              value={text!}
              className={cn(
                styles.formDropdownMenuOption,
                { [styles.formDropdownMenuOptionHighlighted]: index === highlightedIndex },
              )}
              {...itemProps}
            >
              <span title={text || ''} className={styles.formDropdownMenuOptionTitle}>
                {text}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FeedbackFormAutocomplete;
