import {
  Block,
  BlockContent,
  BlockRichTitle,
} from 'src/__generated__/graphqlTypes';
import { OptionalBgColorInterface } from 'types/componentTypes';

export interface ContactUsGetInTouchInterface extends OptionalBgColorInterface {
  title: Block;
  smsButton?: Block;
  feedbackForm: Block;
  phoneSupport: Block;
  isMobileViewWidth: boolean;
  mobileViewMaxWidth: string;
}

export enum GetInTouchContainerSlug {
  TITLE = 'title',
  FEEDBACK_FORM = 'feedbackForm',
  PHONE_SUPPORT = 'phoneSupport',
  SMS_BUTTON = 'smsButton',
}

export interface TitleInterface {
  title?: BlockRichTitle;
  content?: BlockContent;
  id: Block['blockId'];
}
