import { FC } from 'react';
import cn from 'classnames';

import CommonNavBar from 'components/Navigation';
import styles from 'components/Header/Mobile/MobileNavBar.module.scss';
import { MobileNavBarClasses } from 'types/classTypes';
import { Account } from 'src/__generated__/graphqlTypes';
import { DataLink } from 'components/Shared/SharedComponents';
import { NavigationLocation } from 'constants/enums';
import {
  HeaderMobileNavBarWrapperInterface,
  SignInButtonInterface,
  SignOutButtonInterface,
} from 'components/Header/Mobile/declarations';

const SignInButton: FC<SignInButtonInterface> = ({ authorization }) => {
  if (authorization?.signIn) {
    return (
      <DataLink
        link={authorization.signIn}
        href={authorization.signIn.src || ''}
        className={styles.navAuth}
      >
        {authorization.signIn.title}
      </DataLink>
    );
  }

  return null;
};

const SignOutButton: FC<SignOutButtonInterface> = ({ authorization, onSignOut }) => (
  <button
    type="button"
    onClick={onSignOut}
    className={styles.navAuth}
  >
    {(authorization?.collapsed as Account)?.signOut?.title}
  </button>
);

const MobileNavBarWrapper: FC<HeaderMobileNavBarWrapperInterface> = ({
  user,
  isAuthenticated,
  navBar,
  authorization,
  isMobileHeaderNavActive,
  onSignOut,
}) => {
  const classes: MobileNavBarClasses = {
    highOrder: {
      ul: styles.navMobile,
      li: styles.navMobileCollapse,
      link: styles.navMobileCollapseA,
      linkBold: styles.navMobileCollapseABold,
      linkChildFree: styles.navMobileCollapseAChildFree,
      imgChildFree: styles.navMobileCollapseAImageChildFree,
      img: styles.navMobileCollapseAImage,
      icon: styles.navMobileCollapseAIcon,
      imgReveal: styles.navMobileCollapseAImageRevealed,
      imgCollapse: styles.navMobileCollapseAImageCollapsed,
      innerUl: styles.navMobileContent,
    },
    middleOrder: {
      li: styles.navMobileContentLi,
      link: styles.navMobileContentLiA,
      linkBold: styles.navMobileContentLiABold,
      linkChildFree: styles.navMobileContentLiAChildFree,
      imgChildFree: styles.navMobileContentLiAImageChildFree,
      img: styles.navMobileContentLiAImage,
      imgReveal: styles.navMobileContentLiAImageRevealed,
      imgCollapse: styles.navMobileContentLiAImageCollapsed,
      innerUl: styles.navMobileContentUl,
    },
    lowOrder: {
      li: styles.navMobileContentUlLi,
      link: styles.navMobileContentUlLiA,
    },
  };

  const navClasses = cn(
    styles.nav,
    isMobileHeaderNavActive
      ? styles.navShown
      : styles.navHidden,
  );

  return (
    <nav className={navClasses}>
      <div className={styles.navGreeting}>
        {authorization?.greeting}
        {isAuthenticated && ` ${user?.displayNameHeader}`}
      </div>
      <CommonNavBar
        navBar={navBar}
        classes={classes}
        isMobile
        navigationLocation={NavigationLocation.HEADER}
        user={user}
      />
      {isAuthenticated
        ? <SignOutButton authorization={authorization} onSignOut={onSignOut} />
        : <SignInButton authorization={authorization} />}
    </nav>
  );
};

export default MobileNavBarWrapper;
