import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setMobileHeaderNavActivityState } from 'app-redux/actions/appActions';
import MobileHeader from 'components/Header/Mobile/MobileHeader';
import { Store } from 'app-redux/types/storeTypes';
import { MobileHeaderContainerInterface } from 'components/Header/Mobile/declarations';

const MobileHeaderContainer: React.FC<MobileHeaderContainerInterface> = ({
  topBar,
  searchBox,
  mainLogoMobile,
  menuIcon,
  readings,
}) => {
  const dispatch = useDispatch();
  const isMobileHeaderNavActive = useSelector((store: Store) => store
    .server.app.isMobileHeaderNavActive);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  const onClickHeaderMenu = (flag: boolean) => {
    dispatch(setMobileHeaderNavActivityState(flag));
  };

  return (
    <MobileHeader
      topBar={topBar}
      viewerDevice={viewerDevice}
      searchBox={searchBox}
      isMobileView
      mainLogoMobile={mainLogoMobile}
      menuIcon={menuIcon}
      readings={readings}
      isMobileHeaderNavActive={isMobileHeaderNavActive}
      onClickHeaderMenu={onClickHeaderMenu}
    />
  );
};

export default MobileHeaderContainer;
