import React from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import Header from 'components/Header/Header';
import { HeaderContainerInterface } from 'components/Header/declarations';

const HeaderContainer: React.FC<HeaderContainerInterface> = ({ header }) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const isMobileHeaderNavActive = useSelector((store: Store) => store
    .server.app.isMobileHeaderNavActive);

  const {
    authorization,
    top,
    nav,
    searchBox,
    mainLogo,
    mainLogoMobile,
    menuIcon,
    readings,
  } = header;

  return (
    <Header
      authorization={authorization!}
      navBar={nav!}
      topBar={top!}
      searchBox={searchBox!}
      mainLogo={mainLogo!}
      mainLogoMobile={mainLogoMobile!}
      menuIcon={menuIcon!}
      readings={readings!}
      viewerDevice={viewerDevice}
      isMobileViewWidth={isMobileViewWidth}
      isMobileHeaderNavActive={isMobileHeaderNavActive}
    />
  );
};
export default HeaderContainer;
