import type { FC } from 'react';

import { PsychicCtaButtons, useBootChatSolution } from 'entities/PsychicCtaButton';
import { getPsychicCtaButtons } from 'lib/psychic.service';

import { CtaButtonsWrapperInterface } from '../config/declarations';

const CtaButtons: FC<CtaButtonsWrapperInterface> = ({
  psychicFrame,
  psychic,
  shape,
  className,
}) => {
  const bootStatus = useBootChatSolution();

  const cta = getPsychicCtaButtons(psychic, psychicFrame);

  return (
    <PsychicCtaButtons
      shape={shape}
      bootStatus={bootStatus}
      className={className}
      chatButton={cta.chatButton}
      talkButton={cta.talkButton}
      callbackButton={cta.callbackButton}
      messageButton={cta.messageButton}
      psychicFrame={psychicFrame}
      psychic={psychic}
    />
  );
};

export default CtaButtons;
