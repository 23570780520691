import {
  Block,
  Link,
  Psychic,
  Text,
} from 'src/__generated__/graphqlTypes';
import { RightPsychic } from 'types/objectTypes';

export type HintProps = {
  __typename: 'Block' | 'Text';
  text?: Text['text'];
  richText?: Block['content'];
}

export type HeartImageProps = {
  button: Link;
  hint: HintProps;
};

export enum ButtonSlug {
  BUTTON = 'button',
  HINT_REMOVE = 'hint-remove',
  HINT_ADD = 'hint-add',
}

export interface HeartImageInterface {
  psychic: RightPsychic;
  contentfulData: Psychic;
  className?: string;
  iconClassName?: string;
  assetClassName?: string;
  assetFavoriteClassName?: string;
  hintClassName: string;
  hintArrowClassName: string;
}

export interface HintInterface {
  hint: HintProps;
  isAddToFavoriteHintActive: boolean;
  className: string;
  arrowClassName: string;
}
