import type { FC } from 'react';

import { PsychicStatusText } from 'entities/PsychicStatus';
import { getStatus } from 'lib/psychic.service';

import { StatusTextInterface } from '../config/declarations';

/* Nested component */
const StatusText: FC<StatusTextInterface> = ({
  psychic,
  className,
  wrapperClass,
  psychicFrame,
}) => {
  const status = getStatus({ chatStatus: psychic.chatStatus, phoneStatus: psychic.phoneStatus });

  return (
    <PsychicStatusText
      psychic={psychic}
      status={status}
      className={className}
      psychicFrame={psychicFrame}
      wrapperClass={wrapperClass}
    />
  );
};

export default StatusText;
