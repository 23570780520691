/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC } from 'react';

import {
  getCtaButtonConsideration,
  getPsychicCtaButtons,
  getPsychicReadingButtons,
} from 'lib/psychic.service';
import { PsychicImageModule } from 'entities/PsychicPhotoModules';
import { PsychicSimpleViewButtons as Buttons } from 'entities/PsychicSimpleViewButtons';
import { PsychicAvatarWrapperLink } from 'entities/PsychicAvatarWrapperLink';
import { PsychicPhotoModule } from 'constants/enums';

import styles from './styles.module.scss';

import { CardInterface } from '../config/declarations';

const Card: FC<CardInterface> = ({
  psychicSkeleton,
  positionInArray,
  psychic,
  bootStatus,
  view = PsychicPhotoModule.WITH_RATE,
}) => {
  const { profileButton, avatar } = psychicSkeleton;
  const avatarUrl = avatar?.url || '';

  let buttons;

  if (view === PsychicPhotoModule.WITH_READING_DETAIL) {
    buttons = getPsychicReadingButtons(
      psychic,
      psychicSkeleton,
    );
  }

  if (view !== PsychicPhotoModule.WITH_READING_DETAIL || getCtaButtonConsideration(psychic)) {
    buttons = { ...buttons,
      ...getPsychicCtaButtons(
        psychic,
        psychicSkeleton,
      ) };
  }

  return (
    <li className={styles.card}>
      <PsychicAvatarWrapperLink
        className={styles.link}
        positionInArray={positionInArray}
        profileButton={profileButton}
        psychic={psychic}
      >
        <PsychicImageModule
          view={view}
          psychic={psychic}
          psychicSkeleton={psychicSkeleton}
          src={psychic.psychicImageUrl || avatarUrl || ''}
        />
      </PsychicAvatarWrapperLink>
      <Buttons
        bootStatus={bootStatus}
        chatButton={buttons.chatButton}
        talkButton={buttons.talkButton}
        callbackButton={buttons.callbackButton}
        messageButton={buttons.messageButton}
        detailsButton={buttons.detailsButton}
        rescheduleButton={buttons.rescheduleButton}
        reschedulePurchaseButton={buttons.reschedulePurchaseButton}
        psychicFrame={psychicSkeleton}
        psychic={psychic}
      />
    </li>
  );
};

export default Card;
