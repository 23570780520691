/* eslint-disable no-underscore-dangle */
import { FC, useRef } from 'react';
import { useSelector } from 'react-redux';

import { BlockComponentInterface } from 'types/componentTypes';
import { PhoneSupport } from 'components/Sections/ContactUsGetInTouch/PhoneSupport';
import { Block, Input } from 'src/__generated__/graphqlTypes';
import { PhoneSupportContainerSlug as Slug, PhoneSupportReduceType } from 'components/Sections/ContactUsGetInTouch/PhoneSupport/declarations';
import type { Store } from 'app-redux/types/storeTypes';

const slugs = {
  'see-more-countries': Slug.INPUT,
};

const PhoneSupportContainer: FC<BlockComponentInterface> = ({
  block,
  smsButton,
}) => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const isFirstCountrySetUp = useRef<boolean>(false);

  if (!block) {
    return null;
  }

  const { title, contentTypesCollection } = block;
  const countriesAndInput = (contentTypesCollection?.items || []) as Array<Block | Input>;
  const {
    input,
    firstCountry,
    countries,
  } = countriesAndInput.reduce((accumulator, item) => {
    const fieldName = slugs[item.slug || ''];

    if (fieldName) {
      accumulator[fieldName] = item;
    } else if (!isFirstCountrySetUp.current && item.__typename === 'Block') {
      accumulator[Slug.FIRST_COUNTRY] = item;
      isFirstCountrySetUp.current = true;
    }

    if (item.__typename === 'Block') {
      accumulator[Slug.COUNTRIES].push(item);
    }

    return accumulator;
  }, { countries: [] as Array<Block> } as PhoneSupportReduceType);

  return (
    <PhoneSupport
      viewerDevice={viewerDevice}
      title={title!}
      smsButton={smsButton}
      countries={countries}
      firstCountry={firstCountry}
      input={input}
    />
  );
};

export default PhoneSupportContainer;
