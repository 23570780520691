import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { BootState } from 'common-chat-components/enums';
import { setLoadingState } from 'app-redux/actions/appActions';
import { DataButton, DataLink } from 'components/Shared/SharedComponents';
import { Store } from 'app-redux/types/storeTypes';
import { capitalizeFirstLetter } from 'lib/text.service';
import { GAReplacementValue } from 'constants/enums';
import { useExponeaProductSelectedTracking } from 'lib/external/exponeaHooks';

import styles from './styles.module.scss';
import { ButtonIcon } from './Icon';

import {
  getChatBubbleButton,
  launchStatefulChat,
  launchStatelessChat,
} from '../lib/chat';
import { ChatButtonInterface, ConversationOptions } from '../config/declarations';
import { getUrl } from '../lib';

export const ChatButton: FC<ChatButtonInterface> = ({
  psychic,
  href,
  shape,
  button,
  className,
  buttonName,
  bootStatus,
  ...rest
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const dispatch = useDispatch();
  const trackExponea = useExponeaProductSelectedTracking(psychic);

  if (!button) {
    return null;
  }

  const { extId, psychicName } = psychic;
  const url = getUrl(href, button?.src!, { extid: extId.toString(), ischat: '1' });
  const onChatButtonClick = async () => {
    const isChatCannotStart = !user
            || !window.ChatApp
            || typeof window.ChatApp !== 'function'
            || bootStatus === BootState.INVALID;

    if (isChatCannotStart) {
      return;
    }

    trackExponea(buttonName);
    dispatch(setLoadingState(true));
    const bubbleButton = getChatBubbleButton();

    if (bootStatus === BootState.STATEFUL) {
      launchStatefulChat(bubbleButton);
      dispatch(setLoadingState(false));
    } else if (bootStatus === BootState.STATELESS) {
      const conversationOptions: ConversationOptions = {
        psychicId: extId,
        userId: user!.custId,
        token: user!.chatToken,
      };
      launchStatelessChat(
        bubbleButton,
        conversationOptions,
        () => dispatch(setLoadingState(false)),
      );
    }
  };

  const gaData = {
    [GAReplacementValue.PSYCHIC_NAME]: psychicName,
    [GAReplacementValue.EXT_ID]: extId.toString(),
  };
  const classes = cn(
    styles.button,
    styles[`shape${capitalizeFirstLetter(shape)}`],
    styles[`button${buttonName}`],
    className,
  );
  const chatButtonContent = (
    <>
      <ButtonIcon
        isSpriteImage
        image={button.image}
        className={styles.buttonAsset}
        buttonName={buttonName}
      />
      <span className={styles.buttonTitle}>
        {button.title}
      </span>
    </>
  );
  const commonButtonsProps = {
    link: button,
    className: classes,
    gaData,
    ...rest,
  };

  if (isAuthenticated) {
    return (
      <DataButton
        onClick={onChatButtonClick}
        {...commonButtonsProps}
      >
        {chatButtonContent}
      </DataButton>
    );
  }

  return (
    <DataLink
      href={url}
      onClick={() => trackExponea(buttonName)}
      {...commonButtonsProps}
    >
      {chatButtonContent}
    </DataLink>
  );
};

export default ChatButton;
