import {
  FC,
  KeyboardEvent,
  MouseEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { Store } from 'app-redux/types/storeTypes';
import { setLoadingState } from 'app-redux/actions/appActions';
import { HeaderMobileNavBarWrapperContainerInterface } from 'components/Header/Mobile/declarations';
import { MobileNavBarWrapper } from 'components/Header/Mobile';
import { signOut } from 'lib/auth.service';
import { Logger } from 'lib/logger';

const MobileNavBarWrapperContainer: FC<HeaderMobileNavBarWrapperContainerInterface> = ({
  navBar,
  authorization,
  isMobileHeaderNavActive,
}) => {
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const user = useSelector((store: Store) => store.server.auth.user);
  const oaPlatform = useSelector((store: Store) => store.server.app.oaPlatform);
  const dispatch = useDispatch();
  const router = useRouter();
  const onSignOut = async (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      dispatch(setLoadingState(true));
      await signOut(user, oaPlatform);
      router.replace(router.asPath);
    } catch (e) {
      Logger.error(e);
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  return (
    <MobileNavBarWrapper
      user={user}
      onSignOut={onSignOut}
      isAuthenticated={isAuthenticated}
      navBar={navBar}
      authorization={authorization}
      isMobileHeaderNavActive={isMobileHeaderNavActive}
    />
  );
};

export default MobileNavBarWrapperContainer;
