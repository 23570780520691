/* eslint-disable no-underscore-dangle */
import {
  FC,
  Fragment,
  useState,
} from 'react';

import {
  Asset,
  Link,
  NavBar,
  NavItem,
  NavItemHeadline,
  NavItemLinksItem,
} from 'src/__generated__/graphqlTypes';
import { NavBarClasses, NavItemClasses } from 'types/classTypes';
import {
  LINK_TYPE,
  NAV_ITEM_TYPE,
  TEXT_TYPE,
} from 'src/constants/constants';
import { FooterDesktopNavBarItemInterface, FooterMobileNavBarItemInterface } from 'types/componentTypes';
import MobileNavBarLinkItem from 'components/Navigation/Mobile/MobileNavBarLinkItem';
import MobileNavBarTextItem from 'components/Navigation/Mobile/MobileNavBarTextItem';
import DesktopNavBarLinkItem from 'components/Navigation/Desktop/DesktopNavBarLinkItem';
import DesktopNavBarTextItem from 'components/Navigation/Desktop/DesktopNavBarTextItem';
import { CommonNavBarInterface } from 'components/Navigation/declarations';

const mobileNavBarItems = {
  [LINK_TYPE]: MobileNavBarLinkItem,
  [TEXT_TYPE]: MobileNavBarTextItem,
};

const desktopNavBarItems = {
  [LINK_TYPE]: DesktopNavBarLinkItem,
  [TEXT_TYPE]: DesktopNavBarTextItem,
};

const CommonNavBar: FC<CommonNavBarInterface> = ({
  navBar,
  classes,
  isMobile,
  navigationLocation,
  itemEqualWithCurrentPathName,
  user,
}) => {
  const [chevron, setChevron] = useState<Asset | null>(null);

  if (!navBar) {
    return null;
  }

  const buildNavItemHeadline = (
    navHeadline: NavItemHeadline,
    image: Asset | null,
    classes: NavItemClasses,
    isHighlight: boolean,
    children?: any,
  ) => {
    const typename = navHeadline?.__typename;
    const store = isMobile ? mobileNavBarItems : desktopNavBarItems;
    const Component: FC<FooterMobileNavBarItemInterface>
    | FC<FooterDesktopNavBarItemInterface>
    | null = store[typename!];

    if (Component) {
      return (
        <Component
          key={navHeadline.entryName}
          image={image!}
          navItem={navHeadline}
          classes={classes}
          isHighlight={isHighlight}
          navigationLocation={navigationLocation}
          itemEqualWithCurrentPathName={!isMobile ? itemEqualWithCurrentPathName : ''}
        >
          {children}
        </Component>
      );
    }

    return Component;
  };

  const buildLinkItem = (navItem: Link, classes: NavItemClasses) => buildNavItemHeadline(
    navItem as Link,
    null,
    classes,
    // @ts-ignore
    navItem.isHighlight,
  );

  const buildNavItem = (navItem: NavItem, classes: NavBarClasses) => {
    const children = navItem.linksCollection?.items as Array<Link>;

    if (children && children.length > 0) {
      const childrenItems = children.map((navItem: Link) => {
        const typename = navItem?.__typename;

        if (typename === LINK_TYPE) {
          return buildLinkItem(
            navItem,
            classes.lowOrder!,
          );
        }

        return null;
      });

      return buildNavItemHeadline(
        navItem.headline as NavItemHeadline,
        isMobile ? navItem.reveal! : chevron!,
        classes.middleOrder!,
        navItem.isHighlight!,
        childrenItems,
      );
    }

    return buildNavItemHeadline(
      navItem.headline as NavItemHeadline,
      isMobile ? navItem.reveal! : chevron!,
      classes.middleOrder!,
      navItem.isHighlight!,
    );
  };

  const buildNavBar = (
    navBar: NavBar,
    classes: NavBarClasses = {},
  ) => {
    const navItems = navBar?.navItemsCollection?.items as Array<NavItem>;
    const navChevron = navBar.chevron;

    if (!chevron && navChevron) setChevron(navChevron);

    return navItems.map((navItem: NavItem, i) => {
      const children = navItem.linksCollection?.items as Array<NavItemLinksItem>;

      const shouldItemBeHiddenOnNotMobile = !isMobile && navItem.isMobile;
      const shouldItemBeHiddenOnMobile = isMobile && navItem.isMobile === false;
      /* We have to hide some elements on mobile for AC and EC but keep it for NC
         and NC lead. Due to the fact that NC lead is handled as AC or EC in the application,
         we cannot provide with any flag from Contentful that will show that NC lead should be
         considered as NC for this case. So this condition is just a workaround */
      const isLoggedInNcLeadCustomer = user
        && Object.keys(user).length > 1
        && user.isLeadCustomer;

      if (shouldItemBeHiddenOnNotMobile
          || (shouldItemBeHiddenOnMobile && (!isLoggedInNcLeadCustomer))) {
        return null;
      }

      if (children && children.length > 0) {
        const childrenItems = children.map((navItem: NavItem | Link) => {
          const typename = navItem?.__typename;

          if (typename === NAV_ITEM_TYPE) {
            return buildNavItem(navItem as NavItem, classes);
          }

          if (typename === LINK_TYPE) {
            return buildLinkItem(navItem as Link, classes.middleOrder!);
          }

          return null;
        });

        return (
          <Fragment key={i.toString()}>
            {buildNavItemHeadline(
              navItem.headline as NavItemHeadline,
              isMobile ? navItem.reveal! : chevron!,
              classes.highOrder!,
              navItem.isHighlight!,
              childrenItems,
            )}
          </Fragment>
        );
      }

      return buildNavItemHeadline(
        navItem.headline as NavItemHeadline,
        navItem.reveal!,
        classes.highOrder!,
        navItem.isHighlight!,
      );
    });
  };

  return (
    <ul className={classes.highOrder?.ul}>
      {buildNavBar(navBar, classes)}
    </ul>
  );
};

export default CommonNavBar;
