import { CSSProperties, FC } from 'react';
import { useRouter } from 'next/router';

import styles from 'components/Content/Content.module.scss';
import { renderReactComponentsByType } from 'components/Sections';
import { ContentInterface } from 'components/Content/declarations';

const Content: FC<ContentInterface> = ({
  bgColor,
  content,
  className,
  serverCookies,
}) => {
  const router = useRouter();
  const components = renderReactComponentsByType(content, { router, serverCookies });
  const mainStyle: CSSProperties = (bgColor) ? { background: bgColor } : {};

  return (
    <main
      role="main"
      className={styles[className]}
      style={mainStyle}
    >
      {components}
    </main>
  );
};

export default Content;
