import {
  FC,
  Fragment,
  ReactElement,
  useEffect,
} from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { jsonLdScriptProps } from 'react-schemaorg';

import Footer from 'components/Footer';
import Header from 'components/Header';
import styles from 'components/MainLayout.module.scss';
import { MainLayoutInterface } from 'components/declarations';
import { ExternalTags } from 'entities/ExternalTags';
import { renderExternalScripts, renderInlineScripts } from 'lib/tag.service';

export const MainLayout: FC<MainLayoutInterface> = ({
  isDesktopRequested,
  children,
  metaData,
  header,
  isSlugMatchPath,
  footer,
  isMobile,
  desktopViewportWidth,
  isMobileHeaderNavActive,
  externalScripts,
  externalStyles,
  externalTags,
  hideMobileHeaderNavbar,
}) => {
  const router = useRouter();
  const base = process.env.BASE_SERVER_URL || process.env.NEXT_PUBLIC_BASE_SERVER_URL;
  const canonicalUrl = `${base}${metaData.url || router.asPath}`;

  const applyLayout = (content: ReactElement) => (
    <>
      {isSlugMatchPath
        && externalScripts.map(renderExternalScripts)}
      <Head>
        {isDesktopRequested
          ? <meta name="viewport" content={`width=${desktopViewportWidth}`} />
          : <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />}
        {externalStyles.map((style) => (
          // @ts-ignore
          <Fragment key={style.key}>
            {style}
          </Fragment>
        ))}
        {metaData && (
          <>
            <title>
              {metaData.title}
            </title>
            {metaData.favicon && <link rel="icon" href={metaData.favicon} />}
            <link rel="canonical" href={canonicalUrl} />
            {metaData.redirect && <meta httpEquiv="refresh" content={`0; url = ${metaData.redirect}`} />}
            {metaData.robotMetaValue && <meta name="robots" content={metaData.robotMetaValue} key="robots" />}
            {metaData.description && <meta name="description" content={metaData.description} key="description" />}
            {metaData.keywords && <meta name="keywords" content={metaData.keywords} key="keywords" />}
            <meta property="og:url" content={canonicalUrl} />
            <meta name="twitter:card" content="summary" />
            {metaData.type && <meta property="og:type" content={metaData.type} key="ogtype" />}
            {metaData.title && <meta property="og:title" content={metaData.title} key="ogtitle" />}
            {metaData.description && <meta property="og:description" content={metaData.description} key="ogdescription" />}
            {metaData.image.url && <meta property="og:image:url" content={metaData.image.url} key="ogimageurl" />}
            {metaData.siteName && <meta property="og:site_name" content={metaData.siteName} key="ogsitename" />}
            {metaData.locale && <meta property="og:locale" content={metaData.locale} key="oglocale" />}
            {metaData.title && <meta name="twitter:title" content={metaData.title} key="twtitle" />}
            {metaData.description && <meta name="twitter:description" content={metaData.description} key="twdesc" />}
            {metaData.image.url && <meta name="twitter:image" content={metaData.image.url} key="twimage" />}
            {metaData.image.contentType && <meta property="og:image:type" content={metaData.image.contentType} key="ogimagetype" />}
            {metaData.image.width && <meta property="og:image:width" content={`${metaData.image.width}`} key="ogimagewidth" />}
            {metaData.image.height && <meta property="og:image:height" content={`${metaData.image.height}`} key="ogimageheight" />}
            { /* eslint-disable-next-line react/jsx-props-no-spreading */}
            {metaData.schema && <script {...jsonLdScriptProps<any>(metaData.schema)} />}
            {isSlugMatchPath
              && externalScripts.map(renderInlineScripts)}
          </>
        )}
        <meta name="MobileOptimized" content="width" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta charSet="utf-8" />
        {isSlugMatchPath
          && <ExternalTags externalTags={externalTags} />}
      </Head>
      {content}
    </>
  );

  useEffect(() => {
    if (isMobileHeaderNavActive) {
      document.body.style.overflowY = 'hidden';
    } else {
      // @ts-ignore
      document.body.style.overflowY = null;
    }
  }, [isMobileHeaderNavActive]);

  const layoutClasses = cn(
    styles.layout,
    { [styles.layoutTranslated]: isMobileHeaderNavActive && isMobile },
  );

  const backgroundStyles = cn(
    styles.back,
    { [styles.backOpacity]: !isMobileHeaderNavActive },
  );

  const content = (
    <div className={layoutClasses}>
      { header && <Header header={header} /> }
      { children }
      { footer && <Footer footer={footer} /> }
      <div
        role="button"
        className={backgroundStyles}
        aria-label="Background"
        tabIndex={0}
        onClick={hideMobileHeaderNavbar}
        onKeyPress={hideMobileHeaderNavbar}
      />
    </div>
  );

  return applyLayout(content);
};
