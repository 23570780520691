import { FC } from 'react';
import parse from 'html-react-parser';

import {
  DataLink,
  PsychicRate,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { GAReplacementValue } from 'constants/enums';

import styles from './PsychicWithTestimonial.module.scss';

import { PsychicWithTestimonialsInterface } from '../config/declarations';

const PsychicWithTestimonial: FC<PsychicWithTestimonialsInterface> = ({
  psychicWithTestimonial,
  starImage,
  profileLink,
}) => {
  const {
    featuredTestimonialData,
    psychicBioURL,
    psychicName,
    overallScore,
    basePrice,
    customerPrice,
    psychicImageUrl,
    extId,
  } = psychicWithTestimonial;

  const finalProfileLink = (profileLink)
    ? profileLink.src + psychicBioURL
    : psychicBioURL;
  const psychicImage = psychicWithTestimonial.images[5] || psychicImageUrl;

  const { testimonialContent, author } = featuredTestimonialData;

  return (
    <div className={styles.carouselItem}>
      <div className={styles.carouselItemPsychic}>
        <DataLink
          href={finalProfileLink || ''}
          link={profileLink}
          gaData={{
            [GAReplacementValue.PSYCHIC_NAME]: psychicName,
            [GAReplacementValue.EXT_ID]: extId,
          }}
          className={styles.carouselItemPsychicLink}
        >
          <WebpImage
            image={EXTERNAL_WEBP_IMAGE}
            height="100px"
            width="100px"
            src={psychicImage}
            alt={psychicName}
            className={styles.carouselItemPsychicLinkImg}
          />
        </DataLink>
        <div className={styles.carouselItemPsychicData}>
          <span
            className={styles.carouselItemPsychicDataName}
            title={psychicName}
          >
            {psychicName}
          </span>
          <div className={styles.carouselItemPsychicDataStar}>
            <span
              style={{ backgroundImage: `url(${starImage?.url!})` }}
              className={styles.carouselItemPsychicDataStarImg}
            />
            <span className={styles.carouselItemPsychicDataStarRate}>
              {overallScore}
            </span>
          </div>
          <PsychicRate
            pricePerMinute={basePrice}
            priceWithDiscountPerMinute={customerPrice}
            classNames={{
              wrapper: styles.carouselItemPsychicDataPrice,
              commonWithDiscount: styles.carouselItemPsychicDataPriceCrossed,
              discount: styles.carouselItemPsychicDataPriceDiscount,
              common: styles.carouselItemPsychicDataPriceCommon,
            }}
          />
        </div>
      </div>
      <div className={styles.carouselItemTestimonial}>
        <blockquote className={styles.carouselItemTestimonialContent}>
          {parse(testimonialContent)}
        </blockquote>
        <small className={styles.carouselItemTestimonialAuthor}>{author}</small>
      </div>
    </div>
  );
};

export default PsychicWithTestimonial;
