import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';

import SearchBoxComponent from 'components/Header/MiddleBar/Interaction/SearchBox/SearchBox';
import mobileStyles from 'components/Header/Mobile/MobileHeader.module.scss';
import styles from 'components/Header/MiddleBar/Interaction/SearchBox/SearchBox.module.scss';
import { searchPsychicByCriteriaProxy } from 'src/api/psychicApi';
import { SEARCH_BOX_DEBOUNCE_DELAY } from 'constants/constants';
import { SearchBoxWrapperInterface, ClearButtonInterface } from 'components/Header/MiddleBar/Interaction/SearchBox/declarations';

/* Nested component */
const ClearButton: FC<ClearButtonInterface> = ({
  searchBoxClearImage,
  className,
  clear,
}) => (
  <span
    role="button"
    tabIndex={0}
    aria-label={searchBoxClearImage?.title || ''}
    onClick={clear}
    onKeyPress={clear}
    className={className}
  />
);

/* Main component */
const SearchBoxWrapper: FC<SearchBoxWrapperInterface> = ({
  searchBox,
  mobileVisibility,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isFilledInput, setFilledInputState] = useState<boolean>(searchValue.length > 0);
  const [psychicsList, setPsychicsList] = useState<Array<any>>([]);

  const charactersCountRef = useRef<number>(searchValue.length);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const getAndSetSearchValue = useCallback(debounce(async (newSearchValue: string) => {
    if (!newSearchValue) {
      setPsychicsList([]);
    } else {
      const array = await searchPsychicByCriteriaProxy(newSearchValue);
      const isNoCurrentValueButCached = !searchRef.current?.value && newSearchValue;
      setPsychicsList((isNoCurrentValueButCached || !array) ? [] : array);
    }
  }, SEARCH_BOX_DEBOUNCE_DELAY), []);

  const changeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
    await getAndSetSearchValue(newSearchValue);
  };

  useEffect(() => {
    const charactersCount = searchValue.length;

    if (charactersCount > 0) {
      setFilledInputState(true);
    }

    if (charactersCountRef.current > 0 && charactersCount === 0) {
      setFilledInputState(false);
    }

    charactersCountRef.current = charactersCount;
  }, [searchValue]);

  const clear = () => {
    setSearchValue('');
    setFilledInputState(false);
  };

  if (mobileVisibility?.isVisibleSearchBox) {
    const containerClasses = cn(
      mobileStyles.headerMobileSearchBoxWrapper,
      isFilledInput
        ? mobileStyles.headerMobileSearchBoxWrapperFilled
        : mobileStyles.headerMobileSearchBoxWrapperBlank,
    );

    const cancel = () => mobileVisibility.setSearchBoxVisibilityState(false);

    const filledInputExtraElements = (
      <>
        <button
          type="button"
          tabIndex={0}
          className={mobileStyles.headerMobileSearchBoxWrapperCancel}
          onClick={cancel}
          onKeyPress={cancel}
        >
          {searchBox?.cancel?.title}
        </button>
        <ClearButton
          clear={clear}
          searchBoxClearImage={searchBox.clear!}
          className={mobileStyles.headerMobileSearchBoxWrapperClear}
        />
      </>
    );

    return (
      <div className={containerClasses}>
        <SearchBoxComponent
          psychicsList={psychicsList}
          searchBox={searchBox}
          searchContainerRef={searchContainerRef}
          searchRef={searchRef}
          searchValue={searchValue}
          changeHandler={changeHandler}
        />
        {isFilledInput && filledInputExtraElements }
      </div>
    );
  }

  if (!mobileVisibility) {
    return (
      <div className={styles.wrapper}>
        <SearchBoxComponent
          psychicsList={psychicsList}
          searchBox={searchBox}
          searchContainerRef={searchContainerRef}
          searchRef={searchRef}
          searchValue={searchValue}
          changeHandler={changeHandler}
        />
        {isFilledInput && (
          <ClearButton
            clear={clear}
            searchBoxClearImage={searchBox.clear!}
            className={styles.searchClear}
          />
        )}
      </div>
    );
  }

  return null;
};

export default SearchBoxWrapper;
