import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLoadingState } from 'app-redux/actions/appActions';
import type { Store } from 'app-redux/types/storeTypes';
import { setReferrer, setSlug } from 'app-redux/actions/pageActions';
import {
  HOMEPAGE_SLUG,
  ROUTE_CHANGE_COMPLETE,
  ROUTE_CHANGE_START,
} from 'constants/constants';
import { GTM } from 'lib/external/gtm';
import { CustomerType } from 'constants/enums';

import { CustomNextRouter } from './types';
import { getHistoryChangeMethod, setTempReferrerCookie } from './service';

export const useCustomRouter = (): CustomNextRouter => {
  const router = useRouter();

  const replace = getHistoryChangeMethod(router);
  const push = getHistoryChangeMethod(router, 'push');

  return { ...router, replace, push };
};

export const useReferrerInitializer = () => {
  const dispatch = useDispatch();
  const referrer = useSelector((store: Store) => store.server.page.referrer);
  const wasFirstLoad = useRef<boolean>(false);

  useEffect(() => {
    if (!wasFirstLoad.current && !referrer) {
      dispatch(setReferrer(document.referrer));
      wasFirstLoad.current = true;
    }
  }, [dispatch, referrer]);
};

export const useInitialSetup = () => {
  const user = useSelector((store: Store) => store.server.auth.user);

  useEffect(() => {
    GTM.sendEvent('dataLayer-initialized', {
      user_id: user?.customerId || 0,
      user_type: user ? CustomerType.EC : CustomerType.NC,
      logged_in: Boolean(user?.loggedIn),
    });
  }, [user]);

  useReferrerInitializer();
};

const extractSlug = (url?: string) => {
  if (!url || url === '/') {
    return HOMEPAGE_SLUG;
  }

  const pathParts = url.split('/');
  const slug = pathParts[pathParts.length - 1];

  return slug;
};

export const useRouteChangeCompleat = () => {
  const router = useCustomRouter();
  const dispatch = useDispatch();
  const user = useSelector((store: Store) => store.server.auth.user);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      const executeOnIdle = window.requestIdleCallback || setTimeout;
      executeOnIdle(() => {
        GTM.sendEvent('historyChange', {
          page_title: document.querySelector('title')?.innerText,
          page_location: window.location.href,
          page_path: window.location.pathname,
        });
      });

      const slug = extractSlug(window.location.pathname) || HOMEPAGE_SLUG;
      dispatch(setSlug(slug));

      GTM.sendEvent('dataLayer-initialized', {
      	user_id: user?.customerId || 0,
        user_type: user ? CustomerType.EC : CustomerType.NC,
        logged_in: Boolean(user?.loggedIn),
      });
    };

    router.events.on(ROUTE_CHANGE_COMPLETE, handleRouteChangeComplete);

    return () => router.events.off(ROUTE_CHANGE_COMPLETE, handleRouteChangeComplete);
  }, [router, dispatch, user]);
};

export const useRouteChangeStart = () => {
  const router = useCustomRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleRouteChange = () => {
      dispatch(setLoadingState(true));
      setTempReferrerCookie();
    };

    router.events.on(ROUTE_CHANGE_START, handleRouteChange);

    return () => {
      router.events.off(ROUTE_CHANGE_START, handleRouteChange);
    };
  }, [router, dispatch]);
};

export const useRoutingConsistencyCheck = () => {
  const slug = useSelector((store: Store) => store.server.page.slug);
  const router = useCustomRouter();

  if (!slug.includes(HOMEPAGE_SLUG)) {
    return router.asPath.includes(slug);
  }

  return router.asPath.includes(slug) || (slug === HOMEPAGE_SLUG && router.pathname === '/');
};
