import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { ExponeaPsychicProfile } from 'components/Sections/ExponeaPsychicProfile';
import { Psychic } from 'src/__generated__/graphqlTypes';
import { useBootChatSolution } from 'entities/PsychicCtaButton';

const ExponeaPsychicProfileContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const pages = useSelector((store: Store) => store.server.page.pages);
  const slug = useSelector((store: Store) => store.server.page.slug);
  // @ts-ignore
  const { psychic } = pages[slug] || {};
  const [block] = blocks;
  const psychicFrame = (block.contentTypesCollection?.items[0] || {}) as Psychic;
  const bootStatus = useBootChatSolution();

  if (!psychic) {
    return null;
  }

  return (
    <ExponeaPsychicProfile
      psychic={psychic}
      bootStatus={bootStatus}
      psychicFrame={psychicFrame}
      extraData={extraData}
      bgColor={bgColor}
    />
  );
};

export default ExponeaPsychicProfileContainer;
