import { FC } from 'react';
import { useSelector } from 'react-redux';

import TopBar from 'components/Header/TopBar/TopBar';
import { Block, Link } from 'src/__generated__/graphqlTypes';
import { TopBarContainerInterface } from 'components/Header/TopBar/declarations';
import { Store } from 'app-redux/types/storeTypes';
import { ViewerDevice } from 'constants/enums';
import { HELP_TELEPHONE_SLUG } from 'constants/constants';

const TopBarContainer: FC<TopBarContainerInterface> = ({ topBar }) => {
  const { offer, phonesCollection } = topBar;
  const ctmNumber = useSelector((store: Store) => store.client.app.ctmNumber);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const user = useSelector((store: Store) => store.server.auth.user);
  let phoneNumbers = phonesCollection?.items as Array<Block> || [];

  if (user && viewerDevice === ViewerDevice.MOBILE) {
    phoneNumbers = phoneNumbers.filter(((phNumber) => phNumber.slug === HELP_TELEPHONE_SLUG));
  }

  return (
    <TopBar
      phoneNumbers={phoneNumbers}
      offer={offer as Link}
      ctmNumber={ctmNumber}
    />
  );
};

export default TopBarContainer;
