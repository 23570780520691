import React from 'react';

import styles from 'components/Header/MiddleBar/MiddleBar.module.scss';
import Interaction from 'components/Header/MiddleBar/Interaction';
import MainLogo from 'components/Header/MiddleBar/MainLogo';
import DesktopNavBarWrapper from 'components/Header/Desktop/DesktopNavBarWrapper';
import { MiddleBarInterface } from 'components/Header/MiddleBar/declarations';

const MiddleBar: React.FC<MiddleBarInterface> = ({
  mainLogo,
  navBar,
  authorization,
  searchBox,
}) => (
  <div className={styles.middleBar}>
    <div className={styles.middleBarMain}>
      <div className={styles.middleBarMainWrapper}>
        <MainLogo mainLogo={mainLogo} isMobileView={false} />
        <Interaction authorization={authorization} searchBox={searchBox} />
      </div>
      <DesktopNavBarWrapper navBar={navBar} />
    </div>
  </div>
);

export default MiddleBar;
