import { FC } from 'react';
import cn from 'classnames';

import { Link, Text } from 'src/__generated__/graphqlTypes';
import classes from 'src/styles/_commonClasses.module.scss';
import {
  DataButton,
  Icon,
  WebpImage,
} from 'components/Shared/SharedComponents';
import styles from 'components/Sections/ExponeaPsychicProfile/ExponeaPsychicProfile.module.scss';
import {
  CardBarHeartButtonSlugEnum as CardBarHeartButtonSlug,
  HeartImage,
  HeartImageComponentInterface,
  ProfileInteractionInterface,
} from 'components/Sections/ExponeaPsychicProfile/declarations';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { PsychicCtaButtons } from 'entities/PsychicCtaButton';
import { getPsychicCtaButtons } from 'lib/psychic.service';

/* Nested component */
const HeartImageComponent: FC<HeartImageComponentInterface> = ({
  contentfulData,
  isFavorite,
}) => {
  const favoritePsychicCollection = contentfulData.favoritePsychicCollection
    ?.items as Array<Link | Text>;

  const favoritePsychicStyles = (!isFavorite)
    ? styles.heartImgAsset
    : styles.heartImgAssetFavorite;
  const { button } = favoritePsychicCollection.reduce((accumulator, item) => {
    const mutableAccumulator = accumulator;

    if (item.__typename === 'Link' && (item as Link).slug === CardBarHeartButtonSlug.BUTTON) {
      mutableAccumulator.button = item;
    }

    return mutableAccumulator;
  }, {} as HeartImage);

  const { image } = button || {};

  return (
    <>
      <DataButton
        link={button}
        className={cn(styles.heartImg, classes.cursorPointer)}
      >
        <Icon
          image={image!}
          className={favoritePsychicStyles}
        />
      </DataButton>
    </>
  );
};

/* Main component */
export const ProfileInteraction: FC<ProfileInteractionInterface> = ({
  psychic,
  bootStatus,
  psychicFrame,
}) => {
  const cta = getPsychicCtaButtons(
    psychic,
    psychicFrame,
  );

  return (
    <div className={styles.profileInteraction}>
      <div className={styles.profileInteractionFavorite}>
        <HeartImageComponent
          contentfulData={psychicFrame}
          isFavorite={psychic.isFavorite}
        />
        <span className={styles.heartImgText}>
          {psychic.isFavorite
            ? psychicFrame.heartImageHintRemove
            : psychicFrame.heartImageHintAdd}
        </span>
      </div>
      {psychic.isStaffPick && (
        <div className={styles.profileInteractionPick}>
          <WebpImage
            image={EXTERNAL_WEBP_IMAGE}
            src={psychicFrame.staffPick?.image?.url!}
            className={styles.profileInteractionPickImage}
          />
          <span className={styles.profileInteractionPickTitle}>
            {psychicFrame.staffPick?.title}
          </span>
        </div>
      )}
      {psychic.isCustomerPick && (
        <div className={styles.profileInteractionPick}>
          <WebpImage
            image={EXTERNAL_WEBP_IMAGE}
            src={psychicFrame.customerPick?.url!}
            className={styles.profileInteractionPickImage}
          />
        </div>
      )}
      <PsychicCtaButtons
        bootStatus={bootStatus}
        chatButton={cta.chatButton}
        talkButton={cta.talkButton}
        callbackButton={cta.callbackButton}
        messageButton={cta.messageButton}
        psychic={psychic}
        shape="Oval"
        psychicFrame={psychicFrame}
        className={styles.interaction}
      />
    </div>
  );
};

export default ProfileInteraction;
