/* eslint-disable @typescript-eslint/indent */
import { Dispatch, SetStateAction } from 'react';

import { setItemInObjectBySlug } from 'lib/sharedMethods.service';
import type { Alert, Input } from 'src/__generated__/graphqlTypes';
import type { IdNameType, ValidationAndValue } from 'types/objectTypes';

import { InputSlug, feedbackFormSlugs } from '../config/constants';
import { FeedbackFormInterfaceStateValues } from '../config/declarations';

const applyDefaultValue = <T, >(
  value: T,
  input: Input,
  isValid: boolean | null = null,
): ValidationAndValue<T> => ({
    value,
    isValid,
    alerts: input.alertsCollection?.items as Array<Alert> || [],
    placeholder: input.placeholder || '',
    sysId: input.sys.id,
  });

const reducer = setItemInObjectBySlug(feedbackFormSlugs);
export const getComponents = (inputs: Array<Input>) => inputs
  .reduce(reducer, {} as Record<InputSlug, Input>);

export const getInitialValues = ({
  nameInput,
  emailInput,
  phoneNumberInput,
  countryInput,
  requestTypeInput,
  messageInput,
}: Record<InputSlug, Input>) => {
  const initialInputValues = {
    name: applyDefaultValue<string>('', nameInput),
    email: applyDefaultValue<string>('', emailInput),
    phoneNumber: applyDefaultValue<string>('', phoneNumberInput),
    country: applyDefaultValue<string>('', countryInput),
    requestType: applyDefaultValue<IdNameType<string>>({ id: '', name: '' }, requestTypeInput),
    message: applyDefaultValue<string>('', messageInput),
  };

  return initialInputValues;
};

export const findErrorIfNeeded = (
  inputData: ValidationAndValue<IdNameType<string> | string>,
  isValid: boolean | null,
  isValuePresented?: boolean,
) => {
  let { error } = inputData;
  const { alerts, value } = inputData;

  if (!isValid) {
    const condition = (isValuePresented !== undefined) ? isValuePresented : !value;

    if (condition) {
      error = alerts.find((alert) => alert.slug === 'required');
    } else {
      error = alerts.find((alert) => alert.slug === 'invalid');
    }
  }

  return { ...inputData, error };
};

export const getValidation = (
  value: string | IdNameType<string>,
  pattern?: RegExp | string | null,
) => {
  if (typeof value === 'string') {
    return Boolean(value)
        && ((Boolean(pattern) && Boolean((value as string).toLowerCase().match(pattern!)))
          || !pattern);
  }

  return (Boolean(value.id) && Boolean(value.name))
      && ((Boolean(pattern) && Boolean(value.name.toLowerCase().match(pattern!)))
        || !pattern);
};

export const checkInputDataInvalidity = (
  inputValues: FeedbackFormInterfaceStateValues,
  setInputValues: Dispatch<SetStateAction<FeedbackFormInterfaceStateValues>>,
) => {
  let isInvalid = false;
  Object.entries(inputValues)
    .forEach(([key, inputObject]) => {
      const { isValid, value } = inputObject as ValidationAndValue<string | IdNameType<string>>;

      if (!isValid) {
        isInvalid = true;
        const isValuePresented = !value
            || (!(value as IdNameType<string>).id
              && !(value as IdNameType<string>).name);
        const { error } = findErrorIfNeeded(inputObject, isValid, isValuePresented);

        setInputValues((prev) => ({
          ...prev,
          [key]: {
            ...prev[key],
            isValid: false,
            error,
          },
        }));
      }
    });

  return isInvalid;
};

export const getCommonValidationAndError = (
  item: ValidationAndValue<string | IdNameType<string>>,
  pattern?: RegExp | string | null,
  itemValue?: string | IdNameType<string>,
) => {
  const { value, isValid } = item;
  const currentValue = itemValue || value;
  const validation = getValidation(currentValue, pattern);
  const isValidOrNull = (isValid !== null) ? validation : null;
  const { error } = findErrorIfNeeded(
    item,
    isValidOrNull,
    (typeof currentValue === 'string') ? undefined : !validation,
  );

  return { error, validation, isValid: isValidOrNull };
};
