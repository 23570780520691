import {
  FC,
  useRef,
  useState,
} from 'react';
import dynamic from 'next/dynamic';

import AccountSummaryContainer from 'components/Header/MiddleBar/Interaction/AccountSummary';
import styles from 'components/Header/MiddleBar/Interaction/MiddleBarInteraction.module.scss';
import { useClickOutside } from 'lib/shared.hook';
import { Account, LoginForm } from 'src/__generated__/graphqlTypes';
import SearchBoxContainer from 'components/Header/MiddleBar/Interaction/SearchBox';
import { DataLink } from 'components/Shared/SharedComponents';
import {
  MiddleBarInteractionInterface,
  UnauthenticatedInfoInterface,
  AuthenticatedInfoInterface,
} from 'components/Header/MiddleBar/Interaction/declarations';
import MyInbox from 'components/Header/MiddleBar/Interaction/MyInbox';

const LoginFormContainer = dynamic(() => import('components/Header/MiddleBar/Interaction/LoginForm'));

/* Nested component */
const UnauthenticatedInfo: FC<UnauthenticatedInfoInterface> = ({
  signInRef,
  authorization,
  isShownForm,
  setShownFormState,
}) => {
  if (!authorization) {
    return null;
  }

  const { signUp, collapsed, signIn } = authorization;

  return (
    <>
      {signUp && (
        <>
          <DataLink
            href={signUp.src || ''}
            link={signUp}
            className={styles.interactionAuthSignUp}
          >
            {signUp.title}
          </DataLink>
          <div className={styles.interactionAuthDivider} />
        </>
      )}
      <div ref={signInRef} className={styles.interactionAuthSignIn}>
        {signIn && (
          <>
            <button
              type="button"
              aria-label={signIn.title || ''}
              className={styles.interactionAuthSignInButton}
              data-ajax="false"
              onClick={setShownFormState.bind(null, !isShownForm)}
              onKeyPress={setShownFormState.bind(null, !isShownForm)}
            >
              {signIn.title}
            </button>
            <span
              role="button"
              aria-label={signIn.title || ''}
              tabIndex={0}
              style={{ backgroundImage: `url(${signIn.image?.url})` }}
              className={styles.interactionAuthSignInButtonImage}
              onClick={setShownFormState.bind(null, !isShownForm)}
              onKeyPress={setShownFormState.bind(null, !isShownForm)}
            />
          </>
        )}
        <LoginFormContainer
          isShown={isShownForm}
          loginForm={collapsed as LoginForm}
          setShownFormState={setShownFormState}
        />
      </div>
    </>
  );
};

/* Main component */
const AuthenticatedInfo: FC<AuthenticatedInfoInterface> = ({
  user,
  signInRef,
  authorization,
  isShownForm,
  setShownFormState,
}) => {
  const [isClickedFirstTime, setClickedFirstTime] = useState<boolean>(false);

  const setShownFormStateWithFirstClickHandle = () => {
    setShownFormState(!isShownForm);

    if (!isClickedFirstTime) {
      setClickedFirstTime(true);
    }
  };

  if (!authorization) {
    return null;
  }

  const { greeting, summary, collapsed, inbox } = authorization;

  return (
    <>
      <span className={styles.interactionAuthGreeting}>
        {summary && `${greeting} ${user?.displayNameHeader}`}
      </span>
      <div className={styles.interactionAuthDivider} />
      <div className={styles.interactionMyInbox}>
        <MyInbox myInbox={inbox} />
      </div>
      <div className={styles.interactionAuthDivider} />
      <div ref={signInRef} className={styles.interactionAuthSummary}>
        {summary?.title && (
          <>
            <button
              type="button"
              aria-label={summary.title}
              className={styles.interactionAuthSummaryButton}
              data-ajax="false"
              onClick={setShownFormStateWithFirstClickHandle}
              onKeyPress={setShownFormStateWithFirstClickHandle}
            >
              {summary.title}
            </button>

            <span
              role="button"
              aria-label={summary.title}
              tabIndex={0}
              style={{ backgroundImage: `url(${summary.image?.url})` }}
              className={styles.interactionAuthSummaryButtonImage}
              onClick={setShownFormStateWithFirstClickHandle}
              onKeyPress={setShownFormStateWithFirstClickHandle}
            />
          </>
        )}
        <AccountSummaryContainer
          accountSummary={collapsed as Account}
          setShownFormState={setShownFormState}
          isShownForm={isShownForm}
          isClickedFirstTime={isClickedFirstTime}
        />
      </div>
    </>
  );
};

const MiddleBarInteraction: FC<MiddleBarInteractionInterface> = ({
  user,
  authorization,
  searchBox,
  isAuthenticated,
}) => {
  const [isShownForm, setShownFormState] = useState<boolean>(false);
  const signInRef = useRef(null);

  useClickOutside(signInRef, setShownFormState, false);

  return (
    <div className={styles.interaction}>
      <div className={styles.interactionAuth}>
        {!isAuthenticated
          ? (
            <UnauthenticatedInfo
              signInRef={signInRef}
              authorization={authorization}
              isShownForm={isShownForm}
              setShownFormState={setShownFormState}
            />
          )
          : (
            <AuthenticatedInfo
              user={user}
              signInRef={signInRef}
              authorization={authorization}
              isShownForm={isShownForm}
              setShownFormState={setShownFormState}
            />
          )}
      </div>
      <SearchBoxContainer searchBox={searchBox} />
    </div>
  );
};

export default MiddleBarInteraction;
