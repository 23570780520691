import React from 'react';

import styles from 'components/Header/Header.module.scss';
import DesktopHeader from 'components/Header/Desktop';
import MobileHeader, { MobileNavBarWrapperContainer } from 'components/Header/Mobile';
import { ViewerDevice } from 'constants/enums';
import { HeaderInterface } from 'components/Header/declarations';

const Header: React.FC<HeaderInterface> = ({
  authorization,
  navBar,
  topBar,
  searchBox,
  mainLogo,
  mainLogoMobile,
  menuIcon,
  readings,
  viewerDevice,
  isMobileViewWidth,
  isMobileHeaderNavActive,
}) => {
  const showMobileHeaderCondition = isMobileViewWidth || (viewerDevice === ViewerDevice.TABLET);

  return (
    showMobileHeaderCondition
      ? (
        <>
          <MobileNavBarWrapperContainer
            navBar={navBar}
            authorization={authorization}
            isMobileHeaderNavActive={isMobileHeaderNavActive}
          />
          <MobileHeader
            topBar={topBar}
            searchBox={searchBox}
            mainLogoMobile={mainLogoMobile}
            menuIcon={menuIcon}
            readings={readings}
          />
        </>
      )
      : (
        <header className={styles.header}>
          <DesktopHeader
            authorization={authorization}
            navBar={navBar}
            topBar={topBar}
            searchBox={searchBox}
            mainLogo={mainLogo}
          />
        </header>
      )
  );
};

export default Header;
