import { KeyboardEvent, MouseEvent } from 'react';

import { ViewerDevice } from 'constants/enums';
import {
  Asset,
  HeaderAuthorization,
  HeaderTopBar,
  Link,
  SearchBox,
} from 'src/__generated__/graphqlTypes';
import { CommonMobileHeaderInterface, CommonMobileNavBarWrapperInterface } from 'types/componentTypes';
import { UserType } from 'types/objectTypes';

export const HELP_LINK_SLUG = 'help-telephone-mobile';

export interface MobileHeaderContainerInterface
  extends CommonMobileHeaderInterface {
  topBar: HeaderTopBar;
  searchBox: SearchBox;
}

export interface MobileHeaderInterface extends MobileHeaderContainerInterface {
  onClickHeaderMenu: (flag: boolean) => void;
  isMobileHeaderNavActive: boolean;
  viewerDevice: ViewerDevice;
  isMobileView: boolean;
}

export interface HeaderMobileNavBarWrapperContainerInterface
  extends CommonMobileNavBarWrapperInterface {}

export interface HeaderMobileNavBarWrapperInterface
  extends CommonMobileNavBarWrapperInterface {
  user: UserType | null;
  isAuthenticated: boolean;
  onSignOut: (
    event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>
  ) => void;
}

export interface HeaderHelpIconImageInterface {
  image?: Asset;
}

export interface HeaderHelpImageInterface {
  link?: Link;
  links?: Array<Link>;
}

export interface SignInButtonInterface {
  authorization: HeaderAuthorization;
}

export interface SignOutButtonInterface {
  authorization: HeaderAuthorization;
  onSignOut: (
    event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>
  ) => void;
}

export interface HeaderMenuIconInterface {
  menuIcon: Asset;
  toggleNav: () => void;
}

export interface HeaderSearchBoxIconInterface {
  searchBox: SearchBox;
  toggleSearchBox: () => void;
}

export interface HeaderReadingsIconIconInterface {
  readings: Link;
}
