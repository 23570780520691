/* eslint-disable react/jsx-props-no-spreading */
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import LinkComponent from 'next/link';
import { Fragment } from 'react';

import styles from 'src/styles/_commonClasses.module.scss';
import {
  Block,
  Link,
  BlockContent,
} from 'src/__generated__/graphqlTypes';
import {
  CommonRichText,
  DataLink,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { AboutNcCardClasses, UlListClasses } from 'types/classTypes';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  GAReplacementValue,
  Heading,
  ImageFitMode,
} from 'constants/enums';
import { replaceContentfulVariables } from 'lib/text.service';
import { getAppropriatePathForResource } from 'lib/tag.service';
import { useFetchSegmentData } from 'lib/external/segment';

export const mapMarksBold = (
  text: string,
  className?: string,
  data?: any,
) => (
  <strong
    key={text}
    className={className || styles.commonParagraphBold}
    {...data}
  >
    {text}
  </strong>
);

export const mapMarksBoldWithReplacement = (
  replacementObject: Record<string, string | undefined>,
) => (
  text: string | Array<string | boolean>,
  className?: string,
  data?: any,
) => {
  if (Array.isArray(text)) {
    return text.map((singleText) => {
      if (typeof singleText !== 'string') {
        return null;
      }

      const parsedText = replaceContentfulVariables(singleText, replacementObject);

      return mapMarksBold(parsedText, className, data);
    });
  }

  const parsedText = replaceContentfulVariables(text, replacementObject);

  return mapMarksBold(parsedText, className, data);
};

export const mapMarksBoldWithBr = (
  text: string,
  className?: string,
  data?: any,
) => (
  <Fragment key={text}>
    <br />
    <strong
      className={className || styles.commonParagraphBold}
      {...data}
    >
      {text}
    </strong>
  </Fragment>
);

export const mapMarksItalic = (
  text: string,
  className?: string,
  data?: any,
) => (
  <i
    key={text}
    title={text}
    className={className || styles.commonParagraphItalic}
    {...data}
  >
    {text}
  </i>
);

export const mapMarksUnderline = (
  text: string,
  className?: string,
  data?: any,
) => (
  <u
    key={text}
    title={text}
    className={className || styles.commonParagraphUnderline}
    {...data}
  >
    {text}
  </u>
);

export const mapMarksBoldSchema = (data: any) => (
  text: string,
  className?: string,
) => mapMarksBold(text, className, data);

export const mapBlocksEmbeddedAsset = (
  node: any,
  map: Map<string, any>,
  className?: string,
) => {
  const asset: any = map.get(node.data.target.sys.id);

  return (
    <WebpImage
      image={asset}
      key={node.data.target.sys.id}
      className={className}
    />
  );
};

export const mapBlocksHeroImage = (
  node: any,
  map: Map<string, any>,
  className?: string,
) => {
  const asset: any = map.get(node.data.target.sys.id);
  const getWidthLimit = (width: number) => ({
    width,
    media: `${width}px`,
    fit: ImageFitMode.SCALE,
  });
  const imageWidthLimit = [getWidthLimit(400), getWidthLimit(740)];

  return (
    <WebpImage
      image={asset}
      width="100%"
      height="100%"
      className={className}
      widthLimit={imageWidthLimit}
    />
  );
};

export const mapBlocksEmbeddedEntry = (
  node: any,
  map: Map<string, any>,
  className?: string,
) => {
  const entry: any = map.get(node.data.target.sys.id);

  return (
    <button
      key={node.data.target.sys.id}
      type="button"
      style={entry?.styles}
      className={className || styles.commonButton}
    >
      {entry?.text}
    </button>
  );
};

export const mapBlocksEmbeddedEntryLink = (id: string) => (
  node: any,
  map: Map<string, any>,
  className?: string,
) => {
  const link: Link = map.get(node.data.target.sys.id);

  if (!link) {
    return null;
  }

  const segmentObj = useFetchSegmentData(link);

  return (
    <DataLink
      id={id}
      link={link}
      className={className}
      segmentData={segmentObj}
    >
      {link.title}
    </DataLink>
  );
};

export const mapBlocksUlList = (
  node: any,
  className?: UlListClasses,
  data?: any,
) => {
  const listItems: Array<any> | undefined = node?.content;

  if (!listItems) return null;

  const listItemsRepresentation = listItems
    .map((listItem, i) => {
      const paragraphs: Array<any> | undefined = listItem?.content;

      if (!paragraphs) return null;

      const paragraphsRepresentation = paragraphs.map((paragraph, i) => {
        const textNodes: Array<any> | undefined = paragraph?.content;

        if (!textNodes) return null;

        const textNodesRepresentations = textNodes.map((textNode) => textNode.value);

        return (
          <p key={i.toString()} className={className?.p || styles.commonParagraph}>
            {textNodesRepresentations}
          </p>
        );
      });

      return (
        <li className={className?.li} key={i.toString()}>
          {paragraphsRepresentation}
        </li>
      );
    });

  return (
    <ul
      key={listItemsRepresentation.toString()}
      className={className?.ul}
      {...data}
    >
      {listItemsRepresentation}
    </ul>
  );
};

export const mapBlocksUlListSchema = (data: any) => (
  node: any,
  className?: UlListClasses,
) => mapBlocksUlList(node, className, data);

const getPChildKey = (child: string | object, i: number) => ((typeof child === 'string')
  ? child
  : i.toString());

export const mapBlocksParagraph = (
  children: Array<string>,
  className?: string,
  data?: any,
) => (
  <p
    key={children}
    className={className || styles.commonParagraph}
    title={children[0]}
    {...data}
  >
    {children.map((child, i) => (
      <Fragment key={getPChildKey(child, i)}>
        {child}
      </Fragment>
    ))}
  </p>
);

export const mapBlocksParagraphWithExtraData = (
  localData?: Record<string, any>,
) => (
  children: Array<string>,
  className?: string,
  data?: any,
) => mapBlocksParagraph(children, className, { ...data, ...localData });

export const mapBlocksParagraphWithReplacement = (
  replacementObject: Record<string, string | undefined>,
) => ((
  children: Array<string>,
  className?: string,
  data?: any,
) => children.map((child) => {
  const parsedText = replaceContentfulVariables(child, replacementObject);

  return (
    <p
      key={child}
      className={className || styles.commonParagraph}
      {...data}
    >
      {parsedText}

    </p>
  );
}));

export const mapBlocksDiv = (
  children: Array<string>,
  className?: string,
  data?: any,
) => (
  <div
    key={children}
    className={className || styles.commonParagraph}
    {...data}
  >
    {children.map((child, i) => (
      <Fragment key={getPChildKey(child, i)}>
        {child}
      </Fragment>
    ))}
  </div>
);

export const mapBlocksParagraphSchemas = (data: any) => (
  children: any,
  className?: string,
) => mapBlocksParagraph(children, className, data);

export const mapBlocksSpan = (
  children: any,
  className?: string,
) => (
  <span key={children} className={className || styles.commonParagraph}>
    {children}
  </span>
);

export const mapTopBarPhone = (
  children: any,
  className?: string,
) => (
  <>
    {children.map((item: string) => (
      <span key={item} className={className || styles.commonParagraph}>
        {item?.toLowerCase()}
      </span>
    ))}
  </>
);

export const mapBlocksEmbeddedEntryPrice = (
  node: any,
  map: Map<string, any>,
  classNames?: AboutNcCardClasses,
) => {
  const entry: Block = map.get(node.data.target.sys.id);
  const { content, title, link } = entry;
  const parsersConfig: RichTextParsersConfig = {
    [BLOCKS.PARAGRAPH]: {
      classNames: classNames?.contentParagraph || styles.commonParagraph,
    },
    [MARKS.BOLD]: {
      classNames: classNames?.contentBold || styles.commonParagraphBold,
      mapper: mapMarksBoldSchema({ itemProp: 'price' }),
    },
  };

  const existingClassNames = classNames || {} as AboutNcCardClasses;

  return (
    <DataLink
      link={link!}
      href={link?.src!}
      className={existingClassNames.wrapper}
    >
      <span className={existingClassNames.title || styles.commonTitle}>{title}</span>
      <CommonRichText content={content as BlockContent} parsersConfig={parsersConfig} />
    </DataLink>
  );
};

export const mapBlocksMainHeading = (
  children: Array<string>,
  className?: string,
) => (
  <H1 title={children} className={className} />
);

export const mapBlocksSecondHeading = (
  children: Array<string>,
  className?: string,
) => (
  <H2 title={children} className={className} />
);

export const mapBlocksSecondHeadingWithId = (id: string) => (
  children: Array<string>,
  className?: string,
) => (
  <H2
    title={children}
    className={className}
    id={id}
  />
);

export const mapBlocksThirdHeading = (
  children: Array<string>,
  className?: string,
) => (
  <H3 title={children} className={className} />
);

export const mapBlocksFourthHeading = (
  children: any,
  className?: string,
) => (
  <H4 title={children} className={className} />
);

export const mapBlocksFifthHeading = (
  children: any,
  className?: string,
) => (
  <H5 title={children} className={className} />
);

export const mapBlocksSixthHeading = (
  children: any,
  className?: string,
) => (
  <H6 title={children} className={className} />
);

export const mapBlocksHeadingWithId = (id: string = '', heading: Heading = Heading.SECOND) => {
  const headingsMap = {
    [Heading.FIRST]: H1,
    [Heading.SECOND]: H2,
    [Heading.THIRD]: H3,
    [Heading.FOURTH]: H4,
    [Heading.FIFTH]: H5,
    [Heading.SIXTH]: H6,
  };
  const Component = headingsMap[heading];

  return (
    children: Array<string>,
    className?: string,
  ) => (
    <Component
      title={children}
      className={className}
      id={id}
    />
  );
};

export const mapHyperlink = (
  data: any,
  children: Array<string>,
  className?: string,
) => {
  const { isMatched, path } = getAppropriatePathForResource(data.uri);

  if (isMatched) {
    return (
      <a className={className} href={path}>
        {children}
      </a>
    );
  }

  return (
    <LinkComponent href={path}>
      <a className={className}>
        {children}
      </a>
    </LinkComponent>
  );
};

const getHeadingComponent = (heading: Heading) => {
  const headingsMap = {
    [Heading.FIRST]: H1,
    [Heading.SECOND]: H2,
    [Heading.THIRD]: H3,
    [Heading.FOURTH]: H4,
    [Heading.FIFTH]: H5,
    [Heading.SIXTH]: H6,
  };
  const Component = headingsMap[heading];

  return Component;
};

export const mapBlocksHeadingWithReplacement = (
  replacementObject: Record<string, string | undefined>,
  heading: Heading = Heading.SECOND,
) => {
  const Component = getHeadingComponent(heading);

  return (
    children: Array<string>,
    className?: string,
  ) => {
    if (Array.isArray(children)) {
      const titles = children.map((text) => ((typeof text === 'string')
        ? replaceContentfulVariables(text, replacementObject)
        : text));

      return <Component title={titles} className={className} />;
    }

    const title = replaceContentfulVariables(children, replacementObject);

    return <Component title={title} className={className} />;
  };
};

export const mapSmallText = (
  children: Array<string>,
  className?: string,
  data?: any,
) => (
  <small
    key={children.join()}
    className={className}
    {...data}
  >
    {children}
  </small>
);

export const mapHyperlinkWithGAData = (
  link?: Link,
  gaData?: Partial<Record<GAReplacementValue, string>>,
) => (
  data: any,
  children: Array<string>,
  className?: string,
) => {
  if (!link) {
    return mapHyperlink(data, children, className);
  }

  const localLink = { ...link, src: data.uri };

  return (
    <DataLink
      link={localLink}
      className={className}
      gaData={gaData}
    >
      {children}
    </DataLink>
  );
};

export const mapBlocksParagraphWithoutTitle = () => (
  children: string[],
  className?: string,
  data?: any,
) => {
  const newData = { ...data || {}, title: null };

  return mapBlocksParagraph(children, className, newData);
};
