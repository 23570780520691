import {
  Asset,
  Block,
  Link,
} from 'src/__generated__/graphqlTypes';
import { OptionalBgColorInterface } from 'types/componentTypes';

export interface ContactUsHelpCenterInterface extends OptionalBgColorInterface {
  slug: string;
  title: Block;
  newsletters: Block;
  generalInfo: Block;
  contactUsTabletMaxWidth: string;
  isMobileViewWidth: boolean;
  mobileViewMaxWidth: string;
}

export enum HelpCenterContainerSlug {
  TITLE = 'title',
  GENERAL_INFO = 'generalInfo',
  NEWSLETTERS = 'newsletters',
}

export enum HelpCenterImageSizeRatioSlug {
  MOBILE = 0.56,
  ENTRY_IMAGE = 0.5,
}

export interface TitleInterface {
  title: Block['richTitle'];
  content: Block['content'];
  id: Block['blockId'];
}

export interface GeneralInfoImageInterface {
  image?: Asset;
  mobileViewMaxWidth: string;
  isMobileViewWidth: boolean;
}

export interface GeneralInfoInterface {
  helpLinks?: Array<Link>;
  isMobileViewWidth: boolean;
  mobileViewMaxWidth: string;
}
