import { FC, useState } from 'react';
import cn from 'classnames';

import styles from 'components/Header/Mobile/MobileHeader.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import MiddleBarMainLogo from 'src/components/Header/MiddleBar/MainLogo';
import SearchBoxContainer from 'components/Header/MiddleBar/Interaction/SearchBox';
import { DataLink, WebpImage } from 'components/Shared/SharedComponents';
import {
  MobileHeaderInterface,
  HeaderHelpIconImageInterface,
  HeaderHelpImageInterface,
  HeaderMenuIconInterface,
  HeaderSearchBoxIconInterface,
  HeaderReadingsIconIconInterface,
  HELP_LINK_SLUG,
} from 'components/Header/Mobile/declarations';
import TopBar from 'components/Header/TopBar';
import { ViewerDevice } from 'constants/enums';
import { Link } from 'src/__generated__/graphqlTypes';
import { PsychicNotificationIndicator } from 'entities/PsychicNotification';

const MULTIPLICATION_FACTOR = 0.5;

/* Nested component */
const HeaderHelpIconImage: FC<HeaderHelpIconImageInterface> = ({ image }) => {
  if (!image) {
    return null;
  }

  const getSizeComputedSize = (sideSize?: number) => (sideSize
    ? MULTIPLICATION_FACTOR * +sideSize
    : '');

  const { height, width } = image;
  const computedHeight = getSizeComputedSize(height!);
  const computedWidth = getSizeComputedSize(width!);

  return (
    <WebpImage
      image={image}
      additionalProps={{ h: computedHeight, w: computedWidth }}
      height={computedHeight}
      width={computedWidth}
    />
  );
};

/* Nested component */
const HeaderHelpIcon: FC<HeaderHelpImageInterface> = ({ link, links }) => {
  const helpLink = (link?.slug === HELP_LINK_SLUG)
    ? link
    : links?.find((link) => link.slug === HELP_LINK_SLUG);

  if (!helpLink) {
    return null;
  }

  const { image } = helpLink;

  return (
    <DataLink
      link={helpLink}
      className={styles.headerMobileTelephone}
    >
      <HeaderHelpIconImage image={image!} />
    </DataLink>
  );
};

/* Nested component */
const MenuIcon: FC<HeaderMenuIconInterface> = ({ menuIcon, toggleNav }) => {
  if (!menuIcon) {
    return null;
  }

  return (
    <WebpImage
      image={menuIcon}
      role="button"
      tabIndex={0}
      className={styles.headerMobileMenu}
      onClick={toggleNav}
      onKeyPress={toggleNav}
    />
  );
};

/* Nested component */
const SearchBoxIcon: FC<HeaderSearchBoxIconInterface> = ({ searchBox, toggleSearchBox }) => {
  if (!searchBox?.image?.image) {
    return null;
  }

  const { image } = searchBox.image;

  return (
    <DataLink
      link={searchBox.image}
      onClick={toggleSearchBox}
      onKeyPress={toggleSearchBox}
      className={styles.headerMobileSearchBoxButton}
    >
      <WebpImage
        image={image}
        className={cn(classes.searchBoxButton)}
      />
    </DataLink>
  );
};

/* Nested component */
const ReadingsIcon: FC<HeaderReadingsIconIconInterface> = ({ readings }) => {
  if (!readings?.image) {
    return null;
  }

  return (
    <DataLink
      href={readings.src || ''}
      className={styles.headerMobileReadings}
    >
      <div
        className={styles.headerMobileReadingsIcon}
        style={{ backgroundImage: `url(${readings.image.url})` }}
      />
    </DataLink>
  );
};

/* Main component */
const MobileHeader: FC<MobileHeaderInterface> = ({
  topBar,
  viewerDevice,
  searchBox,
  mainLogoMobile,
  menuIcon,
  readings,
  isMobileHeaderNavActive,
  isMobileView,
  onClickHeaderMenu,
}) => {
  const topBarHelpTelephone = topBar?.phonesCollection?.items.find((item) => item!.link);
  const [isVisibleSearchBox, setSearchBoxVisibilityState] = useState<boolean>(false);
  const toggleSearchBox = () => setSearchBoxVisibilityState(!isVisibleSearchBox);
  const toggleMobileHeaderNavActivity = () => onClickHeaderMenu(!isMobileHeaderNavActive);
  const { link, contentTypesCollection } = topBarHelpTelephone || {};

  return (
    <>
      {viewerDevice === ViewerDevice.MOBILE && topBar && <TopBar topBar={topBar} />}
      <header className={styles.header}>
        <div className={styles.headerMobile}>
          <div className={styles.headerMobileIndicator}>
            <PsychicNotificationIndicator />
          </div>
          <MenuIcon menuIcon={menuIcon} toggleNav={toggleMobileHeaderNavActivity} />
          <MiddleBarMainLogo isMobileView={isMobileView} mainLogo={mainLogoMobile} />
          <HeaderHelpIcon link={link!} links={contentTypesCollection?.items as Array<Link>} />
          <SearchBoxIcon searchBox={searchBox} toggleSearchBox={toggleSearchBox} />
          <ReadingsIcon readings={readings} />
          <SearchBoxContainer
            searchBox={searchBox}
            mobileVisibility={{ isVisibleSearchBox, setSearchBoxVisibilityState }}
          />
        </div>
      </header>
    </>
  );
};

export default MobileHeader;
