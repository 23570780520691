import { FC } from 'react';
import cn from 'classnames';

import { DataLink } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { ISmsButtonSection } from '../config/declarations';

const SmsButtonSection: FC<ISmsButtonSection> = ({
  block,
  headingClassName,
  linkClassName,
}) => {
  if (!block) {
    return null;
  }

  const { title, link } = block;

  if (!link) {
    return null;
  }

  return (
    <>
      <h3 className={headingClassName}>{title}</h3>
      <DataLink
        link={link}
        className={cn(styles.button, linkClassName)}
      >
        {link.title}
      </DataLink>
    </>
  );
};

export default SmsButtonSection;
