import {
  FC,
  KeyboardEvent,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import styles from 'components/Header/MiddleBar/Interaction/SearchBox/SearchBox.module.scss';
import { useClickOutside } from 'lib/shared.hook';
import {
  DataButton,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import type {
  SearchBoxInterface,
  PsychicsListInterface,
  SearchButtonInterface,
} from 'components/Header/MiddleBar/Interaction/SearchBox/declarations';
import { getAppropriatePathForResource } from 'lib/tag.service';
// import contentfulPages from 'lib/contentful/pages';

/* Nested component */
const SearchButton: FC<SearchButtonInterface> = ({ button, search }) => {
  if (!button) {
    return null;
  }

  const { image } = button;

  return (
    <DataButton
      link={button}
      onClick={search}
      className={styles.searchButton}
    >
      {image?.url && (
        <WebpImage
          image={image}
          className={styles.searchButtonImage}
        />
      )}
    </DataButton>
  );
};

/* Nested component */
const PsychicsList: FC<PsychicsListInterface> = ({
  baseLink,
  psychicsList,
  isPsychicListVisible,
}) => {
  if (!psychicsList || psychicsList.length === 0 || !isPsychicListVisible) {
    return null;
  }

  return (
    <ul className={styles.searchList}>
      {psychicsList.map((psychic) => (
        <li
          key={psychic.extId}
          className={styles.searchListElement}
        >
          <DataLink
            href={`${baseLink}?search=${psychic.lineName}&extid=${psychic.extId}`}
            className={styles.searchListElementLink}
          >
            {psychic.lineName}
          </DataLink>
        </li>
      ))}
    </ul>
  );
};

/* Main component */
const SearchBox: FC<SearchBoxInterface> = ({
  searchBox,
  psychicsList,
  searchRef,
  searchContainerRef,
  searchValue,
  changeHandler,
}) => {
  const router = useRouter();
  const [isPsychicListVisible, setPsychicListVisibilityState] = useState<boolean>(false);
  useClickOutside(searchContainerRef, setPsychicListVisibilityState.bind(this, false));

  if (!searchBox) {
    return null;
  }

  const { image: button, placeholder, placeholderImage } = searchBox;

  const search = () => {
    const trimmedSearchValue = searchValue.trim();

    if (trimmedSearchValue) {
      const { isMatched, path } = getAppropriatePathForResource(searchBox.baseLink!);
      const href = `${path}?search=${trimmedSearchValue}`;

      if (isMatched) {
        window.location.href = href;
      } else {
        router.replace(href);
      }
    }
  };

  const onPressSend = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      search();
    }
  };

  return (
    <div className={styles.search} ref={searchContainerRef}>
      {placeholderImage && (
        <WebpImage
          image={placeholderImage}
          className={styles.searchInputIcon}
        />
      )}
      <input
        ref={searchRef}
        value={searchValue}
        onKeyUp={onPressSend}
        onChange={changeHandler}
        type="text"
        id="headersearch"
        className={styles.searchInput}
        onFocus={setPsychicListVisibilityState.bind(this, true)}
        data-type="search"
        placeholder={placeholder || ''}
      />
      <SearchButton
        button={button}
        search={search}
      />
      <PsychicsList
        baseLink={searchBox.baseLink!}
        psychicsList={psychicsList}
        isPsychicListVisible={isPsychicListVisible}
      />
    </div>
  );
};

export default SearchBox;
