import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cookie from 'cookie';
import { useSelector } from 'react-redux';

import type { Link } from 'src/__generated__/graphqlTypes';
import { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText, DataButton } from 'components/Shared/SharedComponents';
import { replaceContentfulVariables } from 'lib/text.service';
import { mapMarksBoldWithReplacement } from 'lib/richTextMappers';
import { Logger } from 'lib/logger';
import { SourcePlatforms } from 'common-chat-components/enums';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { OAPlatform } from 'constants/enums';
import { Store } from 'app-redux/types/storeTypes';

import styles from './styles.module.scss';

import type { IOfflineMassageButton } from '../../config/declarations';
import {
  getBalanceToAdd,
  isSufficientFunds,
  useMessageButtonContext,
} from '../../lib/message';
import { ButtonIcon } from '../Icon';

const getSourcePlatform = (platform: OAPlatform) => {
  switch (platform) {
    case OAPlatform.ANDROID: {
      return SourcePlatforms.MobileWebAndroid;
    }
    case OAPlatform.IOS: {
      return SourcePlatforms.MobileWebIOS;
    }
    default: {
      return SourcePlatforms.WebDesktop;
    }
  }
};

const getParsersConfig = (replacementValues: Record<string, any>): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.text,
  },
  [MARKS.BOLD]: {
    mapper: mapMarksBoldWithReplacement(replacementValues),
  },
});

const OfflineMassageButton: FC<IOfflineMassageButton> = ({
  block,
  psychic,
  telephone,
  messageIcon,
  onClick,
}) => {
  const platform = useSelector((store: Store) => store.server.app.oaPlatform);
  const user = useSelector((store: Store) => store.server.auth.user);
  const { customerDetails: details, isButtonDisabled } = useMessageButtonContext();
  const router = useCustomRouter();

  if (!details || !block) {
    return null;
  }

  if (isSufficientFunds(psychic.customerPrice, details)) {
    const { link } = block;

    return (
      <DataButton
        onClick={onClick}
        disabled={isButtonDisabled}
        className={styles.offlineButton}
      >
        <ButtonIcon
          isSpriteImage={false}
          image={messageIcon}
          className=""
          buttonName="Message"
        />
        {link?.title}
      </DataButton>
    );
  }

  const balanceToAdd = getBalanceToAdd(psychic.customerPrice, details!);
  const link = (block.contentTypesCollection?.items as Array<Link>)
    ?.find((item) => item.slug === 'link');
  const balanceReplacement = {
    required: `$${balanceToAdd.toFixed(2)}`,
    current: `$${details.dollarBalance?.toFixed(2)}`,
  };
  const linkExtraTitle = replaceContentfulVariables(link?.alt!, balanceReplacement);
  const linkSrc = replaceContentfulVariables(link?.src!, { extId: `${psychic.extId}` });

  return (
    <div className={styles.insufficientFunds}>
      <hr className={styles.divider} />
      <CommonRichText
        content={block.content}
        parsersConfig={getParsersConfig(balanceReplacement)}
      />
      {link && (
        <DataButton
          link={link}
          onClick={() => {
            try {
              const callingCode = `+${telephone?.countryCallingCode}`;
              const value = JSON.stringify({
                callingCode,
                isDM: 1,
                smsdata: {
                  sourcePlatform: getSourcePlatform(platform),
                  ExtId: psychic.extId,
                  CustomerId: user?.custId,
                  sendSMSToPhoneNumber: `${callingCode}${telephone?.phoneNumber || ''}`,
                },
              });
              const dmessage = cookie.serialize(
                'dmessage',
                value,
                { secure: process.env.NODE_ENV === 'production' },
              );
              document.cookie = dmessage;
            } catch (e) {
              Logger.warn('Error while stringify \'dmessage\' cookie', e);
            }
            router.push(linkSrc);
          }}
          disabled={isButtonDisabled}
          className={styles.button}
        >
          <ButtonIcon
            isSpriteImage={false}
            image={messageIcon}
            className=""
            buttonName="Message"
          />
          <div className={styles.buttonText}>
            <span className={styles.buttonTextTop}>
              {linkExtraTitle}
            </span>
            {link.title}
          </div>
        </DataButton>
      )}
    </div>
  );
};

export default OfflineMassageButton;
