import {
  ChangeEvent,
  FC,
  MouseEvent,
} from 'react';
import cn from 'classnames';

import styles from './Components.module.scss';
import Arrow from './Arrow';

import type { IInputAutocomplete } from '../../config/declarations';

const InputAutocomplete: FC<IInputAutocomplete> = ({
  title,
  name,
  props,
  className,
  buttonProps,
  openMenu,
  onClick,
  onChange,
}) => {
  const onInputClick = (e: MouseEvent<HTMLInputElement>) => {
    onClick(e);
    openMenu();
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    props?.onChange(e);
  };

  const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    onClick(e);
    buttonProps.onClick(e);
  };

  return (
    <div className={cn(styles.interactive, className)}>
      <input
        name={name}
        className={cn(styles.interactiveInput, styles.interactivePadding)}
        placeholder={title}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onClick={onInputClick}
        onChange={onInputChange}
      />
      <Arrow props={buttonProps} onClick={onButtonClick} />
    </div>
  );
};

export default InputAutocomplete;
