import { FC } from 'react';

import { DataLink, PsychicRate } from 'components/Shared/SharedComponents';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { GAReplacementValue } from 'constants/enums';
import { PsychicCardImage } from 'entities/PsychicCardImage';
import { PsychicStatusIndicator, PsychicStatusText } from 'entities/PsychicStatus';
import { HeartImage } from 'entities/PsychicHeartImage';
import Icon from 'src/shared/ui/Icon';

import styles from './styles.module.scss';

import {
  PsychicDescriptionAssetInterface as DescriptionAssetInterface,
  PsychicDescriptionInfoInterface,
  PsychicDescriptionInterface,
} from '../config/declarations';

/* Nested component */
const DescriptionAsset: FC<DescriptionAssetInterface> = ({
  status,
  psychic,
  imageUrl,
  profileUrl,
  psychicName,
  contentfulData,
}) => (
  <div className={styles.descriptionAsset}>
    <DataLink
      link={contentfulData.profileButton!}
      href={profileUrl}
      className={styles.descriptionAssetLink}
      gaData={{ [GAReplacementValue.PSYCHIC_NAME]: psychicName }}
    >
      <PsychicStatusIndicator
        status={status}
        className={styles.statusIndicator}
      />
      <PsychicCardImage
        isLazy={false}
        psychic={contentfulData}
        image={EXTERNAL_WEBP_IMAGE}
        src={imageUrl}
        alt={psychicName}
        className={styles.descriptionAssetLinkImg}
      />
    </DataLink>
    <PsychicStatusText
      psychic={psychic}
      status={status}
      className={styles.statusText}
      psychicFrame={contentfulData}
      wrapperClass={styles.statusTextWrapper}
    />
  </div>
);

/* Nested component */
const DescriptionInfo: FC<PsychicDescriptionInfoInterface> = ({
  psychic,
  responses,
  profileUrl,
  starRating,
  psychicName,
  ratePerMinute,
  contentfulData,
  bio,
}) => {
  const priceStyles = {
    wrapper: styles.descriptionInfoPrice,
    common: styles.descriptionInfoPriceCommon,
    commonWithDiscount: styles.descriptionInfoPriceCrossed,
    discount: styles.descriptionInfoPriceDiscount,
  };

  return (
    <div className={styles.descriptionInfo}>
      <div className={styles.descriptionInfoTitle}>
        <DataLink
          link={contentfulData.profileButton!}
          href={profileUrl}
          className={styles.descriptionInfoTitleName}
        >
          {psychicName}
        </DataLink>
        <HeartImage
          className={styles.heartImg}
          contentfulData={contentfulData}
          psychic={psychic}
          assetClassName={styles.heartImgAsset}
          assetFavoriteClassName={styles.heartImgAssetFavorite}
          hintClassName={styles.heartImgHint}
          hintArrowClassName={styles.heartImgHintArrow}
        />
      </div>
      <div className={styles.descriptionInfoRating}>
        {starRating}
        <Icon
          image={contentfulData.starImage}
          className={styles.descriptionInfoRatingIcon}
        />
        {`(${responses})`}
      </div>
      <PsychicRate
        pricePerMinute={ratePerMinute.price}
        priceWithDiscountPerMinute={ratePerMinute.discount}
        classNames={priceStyles}
      />
      <p className={styles.descriptionInfoBio}>
        {bio}
      </p>
    </div>
  );
};

/* Main component */
const PsychicDescription: FC<PsychicDescriptionInterface> = ({
  bio,
  status,
  psychic,
  imageUrl,
  responses,
  starRating,
  profilePath,
  psychicName,
  ratePerMinute,
  contentfulData,
}) => {
  const profileUrl = (contentfulData.profileButton?.src)
    ? contentfulData.profileButton.src + profilePath
    : profilePath;

  return (
    <div className={styles.description}>
      <DescriptionAsset
        profileUrl={profileUrl}
        status={status}
        psychic={psychic}
        imageUrl={imageUrl}
        psychicName={psychicName}
        contentfulData={contentfulData}
      />
      <DescriptionInfo
        profileUrl={profileUrl}
        psychic={psychic}
        psychicName={psychicName}
        contentfulData={contentfulData}
        starRating={starRating}
        responses={responses}
        ratePerMinute={ratePerMinute}
        bio={bio}
      />
    </div>
  );
};

export default PsychicDescription;
