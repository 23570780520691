import { RefObject } from 'react';

import { IMG_NODE_NAME, SOURCE_NODE_NAME } from 'constants/constants';
import { Psychic } from 'src/__generated__/graphqlTypes';

export const setDefaultImgOnError = (
  contentfulData: Psychic,
  psychicImageRef: RefObject<HTMLImageElement>,
): void => {
  const { avatar } = contentfulData;
  const isNoImportantData = !psychicImageRef
      || !psychicImageRef.current
      || !avatar?.url;
  const isUrlsEqual = avatar?.url === psychicImageRef.current!.src;

  if (isNoImportantData || isUrlsEqual) {
    return;
  }

  const parent = psychicImageRef.current!.parentNode;

  if (!parent) {
    return;
  }

  parent.childNodes.forEach((node) => {
    const newNode = node;

    if (newNode.nodeName === SOURCE_NODE_NAME) {
      (newNode as HTMLSourceElement).srcset = avatar?.url || '';
    }

    if (node.nodeName === IMG_NODE_NAME) {
      (newNode as HTMLImageElement).src = avatar?.url || '';
    }
  });
};
