import { FC } from 'react';
import { useSelector } from 'react-redux';

import { SectionComponentInterface } from 'types/componentTypes';
import { Block } from 'src/__generated__/graphqlTypes';
import { Store } from 'app-redux/types/storeTypes';
import GetInTouch from 'components/Sections/ContactUsGetInTouch/GetInTouch';
import { GetInTouchContainerSlug as Slug } from 'components/Sections/ContactUsGetInTouch/declarations';

const getInTouchSlugs = {
  'get-in-touch': Slug.TITLE,
  'feedback-form': Slug.FEEDBACK_FORM,
  'phone-support': Slug.PHONE_SUPPORT,
  'sms-button': Slug.SMS_BUTTON,
};

const GetInTouchContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const isMobileViewWidth = useSelector(
    (store: Store) => store.server.app.isMobileViewWidth,
  );
  const { mobileViewMaxWidth } = useSelector(
    (store: Store) => store.server.app.scssVariables,
  );

  if (!blocks?.length) {
    return null;
  }

  const {
    title,
    feedbackForm,
    phoneSupport,
    smsButton,
  } = blocks.reduce(
    (accumulator, item) => {
      const fieldName = getInTouchSlugs[item.slug || ''];

      if (fieldName) {
        accumulator[fieldName] = item;
      }

      return accumulator;
    },
    {} as Record<Slug, Block>,
  );

  return (
    <>
      <GetInTouch
        title={title}
        smsButton={smsButton}
        feedbackForm={feedbackForm}
        phoneSupport={phoneSupport}
        bgColor={bgColor}
        isMobileViewWidth={isMobileViewWidth}
        mobileViewMaxWidth={mobileViewMaxWidth}
      />
    </>
  );
};

export default GetInTouchContainer;
