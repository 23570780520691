import React, { KeyboardEvent } from 'react';
import cn from 'classnames';
import cookie from 'js-cookie';

import styles from 'components/Header/MiddleBar/Interaction/AccountSummary/AcountSummary.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { Block, Link } from 'src/__generated__/graphqlTypes';
import { DataLink } from 'components/Shared/SharedComponents';
import { AccountSummaryInterface } from 'components/Header/MiddleBar/Interaction/AccountSummary/declarations';
import { PAYMENT_SETTINGS, LABEL_COOKIE_NAME } from 'constants/constants';
import { Logger } from 'lib/logger';
import { updateOrAddCookie } from 'lib/sharedMethods.service';

const AccountSummary: React.FC<AccountSummaryInterface> = ({
  readingsCount,
  customerDetails,
  isShownForm,
  accountSummary,
  isKarmaMember,
  onSignOut,
}) => {
  const onSignOutKeyPress = (e: KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter') {
      onSignOut(e);
    }
  };

  const checkIfNewButtonVisible = () => {
    try {
      const labelCookieStr = cookie.get(LABEL_COOKIE_NAME);

      if (!labelCookieStr) {
        return true;
      }

      const labelCookieValue = JSON.parse(labelCookieStr ? decodeURI(labelCookieStr) : '');
      const isNewButtonVisibleItem = labelCookieValue?.newAutoReloadLabel?.show;

      return isNewButtonVisibleItem;
    } catch (e) {
      Logger.error(e);

      return false;
    }
  };
  const updateLabelnewAutoReloadLabelKey = () => {
    const newAutoReloadLabelCookie = {
      show: false,
      updatedDate: new Date(),
    };
    updateOrAddCookie('newAutoReloadLabel', newAutoReloadLabelCookie, LABEL_COOKIE_NAME);
  };

  const applyLinks = (
    block: Block,
    className: string,
    value?: string,
  ) => block?.contentTypesCollection?.items
    .map((item) => {
      const newItem = item as Link;
      const newValue = (value) ? `${value} ${newItem.title}` : newItem.title;

      if (!newItem) return null;

      const isNewButtonVisibleItem = newItem?.slug === PAYMENT_SETTINGS
       && checkIfNewButtonVisible();

      return (
        <DataLink
          key={newItem.entryName}
          link={newItem}
          href={newItem.src || ''}
          className={className}
          {...isNewButtonVisibleItem && { onClick: updateLabelnewAutoReloadLabelKey }}
        >
          {newValue}
          {isNewButtonVisibleItem && (
            <p className={styles.accountHomeNewButton}>{newItem?.alt}</p>
          ) }
        </DataLink>
      );
    });

  const applyScore = (value: string, block: Block) => {
    if (!block) return null;

    const { title, contentTypesCollection } = block;
    let karmaLink: Link | null = null;
    const karmaLinkCollection = contentTypesCollection?.items;

    if (karmaLinkCollection) {
      const index = (isKarmaMember && karmaLinkCollection.length) > 1 ? 1 : 0;
      karmaLink = karmaLinkCollection[index] as Link;
    }

    return (
      <div className={styles.accountHomeScoresBlock}>
        <span className={styles.accountHomeScoresBlockHeadline}>
          <span className={styles.accountHomeScoresBlockHeadlineText}>
            {title}
          </span>
          <span className={styles.accountHomeScoresBlockHeadlineText}>
            {value}
          </span>
        </span>
        {karmaLink && (
          <DataLink
            link={karmaLink}
            href={karmaLink.src || ''}
            className={styles.accountHomeScoresBlockLink}
          >
            {karmaLink.title}
          </DataLink>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={cn(styles.account, !isShownForm && classes.displayNone)}>
        <div className={styles.accountHome}>
          <div className={styles.accountHomeLinks}>
            <span className={styles.accountHomeLinksTitle}>
              {accountSummary.accountHome?.title}
            </span>
            {applyLinks(accountSummary.accountHome!, styles.accountHomeLinksLink)}
          </div>
          <div className={styles.accountHomeScores}>
            { applyScore(`$${customerDetails.dollarBalance}`, accountSummary.accountBalance!)}
            { applyScore(`${customerDetails.karmaPoints} pts`, accountSummary.karmaRewards!)}
          </div>
        </div>
        <div className={styles.accountReadings}>
          <span className={styles.accountReadingsTitle}>
            {accountSummary.readings?.title}
          </span>
          {applyLinks(
            accountSummary.readings!,
            styles.accountReadingsLink,
            `${readingsCount}`,
          )}
        </div>
        <a
          role="button"
          tabIndex={0}
          oa-data-ajax="false"
          data-ajax="false"
          className={styles.accountSignOut}
          onClick={onSignOut}
          onKeyPress={onSignOutKeyPress}
        >
          {accountSummary.signOut?.title}
        </a>
      </div>
      <div className={cn(styles.accountArrow, !isShownForm && classes.displayNone)} />
    </>
  );
};

export default AccountSummary;
