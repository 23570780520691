import React, { memo } from 'react';

import TopBar from 'components/Header/TopBar';
import MiddleBar from 'components/Header/MiddleBar';
import { DesktopHeaderInterface } from 'components/Header/Desktop/declarations';

const DesktopHeader: React.FC<DesktopHeaderInterface> = ({
  authorization,
  navBar,
  topBar,
  searchBox,
  mainLogo,
}) => (
  <>
    {topBar && <TopBar topBar={topBar} />}
    <MiddleBar
      searchBox={searchBox}
      navBar={navBar}
      authorization={authorization}
      mainLogo={mainLogo}
    />
  </>
);

export default memo(DesktopHeader);
