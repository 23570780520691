import { getTestimonialsByExtIdProxy } from 'src/api/psychicApi';
import type { RightPsychic } from 'types/objectTypes';

export const getPsychicsWithTestimonials = async (
  psychics: Array<RightPsychic>,
  custId?: string,
) => {
  const pageSize = 1;
  const psychicPromises = psychics
    ?.map(({ extId }) => getTestimonialsByExtIdProxy({
      extId: extId?.toString(),
      pageSize,
      custId,
    }));
  const psychicsWithTestimonials = await Promise.all([...psychicPromises]);

  return psychicsWithTestimonials;
};
