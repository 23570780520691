import { Fragment, FC } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import cn from 'classnames';
import dynamic from 'next/dynamic';

import styles from 'components/Header/TopBar/TopBar.module.scss';
import { RichTextParsersConfig } from 'types/objectTypes';
import { Block } from 'src/__generated__/graphqlTypes';
import { mapTopBarPhone } from 'lib/richTextMappers';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import { TopBarInterface } from 'components/Header/TopBar/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.topBarPhonesHelpText,
    mapper: mapTopBarPhone,
  },
};

const TopBarPhone = dynamic(() => import('components/Header/TopBar/TopBarPhone'), {
  ssr: false,
});

const TopBar: FC<TopBarInterface> = ({ phoneNumbers, offer, ctmNumber }) => {
  const phoneNumberLength = phoneNumbers.length;

  const buildComponents = () => (
    phoneNumbers.map((block: Block, i: number) => {
      const { content, title, link } = block;

      if (!content) {
        return null;
      }

      const isLastElem = phoneNumberLength - 1 === i;

      return (
        <Fragment key={link?.entryName}>
          <div className={styles.topBarPhonesHelp}>
            <TopBarPhone
              title={title}
              link={link}
              isLastElem={isLastElem}
              ctmNumber={ctmNumber}
            />
            <CommonRichText content={content} parsersConfig={parsersConfig} />
          </div>
          {!isLastElem && <span className={styles.topBarPhonesHelpDivider} />}
        </Fragment>
      );
    })
  );

  return (
    <div className={styles.topBar}>
      <div className={cn(
        styles.topBarPhones,
        { [styles.topBarRightOffset]: !offer },
      )}
      >
        {buildComponents()}
      </div>

      {offer && (
        <div className={cn(styles.topBarOffer, styles.topBarRightOffset)}>
          <DataLink
            href={offer.src || ''}
            link={offer}
            className={styles.topBarOfferLink}
          >
            {offer.title}
          </DataLink>
        </div>
      )}
    </div>
  );
};

export default TopBar;
