import { FC } from 'react';

import styles from 'components/Header/MiddleBar/Interaction/MyInbox/MyInbox.module.scss';
import { DataLink, WebpImage } from 'components/Shared/SharedComponents';
import type { MyInboxEnvelopeInterface, MyInboxInterface } from 'components/Header/MiddleBar/Interaction/MyInbox/declarations';
import { PsychicNotificationIndicator } from 'entities/PsychicNotification';

const Envelope: FC<MyInboxEnvelopeInterface> = ({ image }) => {
  if (!image?.url) {
    return null;
  }

  return (
    <div className={styles.inboxEnvelope}>
      <PsychicNotificationIndicator
        className={styles.inboxEnvelopeIndicator}
      />
      <WebpImage
        src={image.url}
        image="external"
        className={styles.inboxEnvelopeImage}
      />
    </div>
  );
};

const MyInbox: FC<MyInboxInterface> = ({
  myInbox,
  showTitle = true,
}) => {
  if (!myInbox) {
    return null;
  }

  const { image } = myInbox;

  return (
    <DataLink
      link={myInbox}
      className={styles.inbox}
    >
      <Envelope image={image} />
      {showTitle && (
        <p className={styles.inboxTitle}>
          {myInbox.title}
        </p>
      )}
    </DataLink>
  );
};

export default MyInbox;
