/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cn from 'classnames';
import { useSelect } from 'downshift';

import styles from 'components/Sections/ContactUsGetInTouch/PhoneSupport/PhoneSupport.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { DataButton, WebpImage } from 'components/Shared/SharedComponents';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { PhoneSupportSelectInterface } from 'components/Sections/ContactUsGetInTouch/PhoneSupport/declarations';
import { getSelectItemId } from 'lib/sharedMethods.service';

const PhoneSupportSelect: React.FC<PhoneSupportSelectInterface> = ({
  id,
  className,
  options,
  placeholder,
  setSelectedItem,
}) => {
  const {
    selectedItem,
    isOpen,
    getMenuProps,
    getToggleButtonProps,
    getItemProps,
  } = useSelect({ items: options });

  const applySelectedItem = () => {
    if (!selectedItem?.title) {
      return (
        <span
          className={styles.phoneSupportCountriesDropdownButtonPlaceholder}
        >
          {placeholder}
        </span>
      );
    }

    const { image, title } = selectedItem?.link || {};

    return (
      <div className={styles.phoneSupportCountriesDropdownButtonSelected}>
        {image && (
          <WebpImage
            image={EXTERNAL_WEBP_IMAGE}
            alt={image.title!}
            src={image.url!}
            pictureClassName={styles.phoneSupportCountriesDropdownMenuOptionPicture}
            className={styles.phoneSupportCountriesDropdownMenuOptionImage}
          />
        )}
        {title && (
          <span className={styles.phoneSupportCountriesDropdownMenuOptionTitle}>
            {title}
          </span>
        )}
      </div>
    );
  };

  const applyArrows = () => {
    const baseClass = 'phoneSupportCountriesDropdownButtonArrow';

    return (
      <div className={styles[baseClass]}>
        <span className={styles[`${baseClass}Up`]} />
        <span className={styles[`${baseClass}Down`]} />
      </div>
    );
  };

  return (
    <div className={cn(styles.phoneSupportCountriesDropdown, className)}>
      <button
        type="button"
        className={styles.phoneSupportCountriesDropdownButton}
        {...getToggleButtonProps(getSelectItemId(id, 'button'))}
      >
        {applySelectedItem()}
        {applyArrows()}
      </button>
      <ul
        {...getMenuProps(getSelectItemId(id, 'ul'))}
        className={cn(
          styles.phoneSupportCountriesDropdownMenu,
          !isOpen && cn(classes.visibilityHidden, classes.zeroHeight),
        )}
      >
        {options?.map((block, index) => {
          const { entryName, link } = block;

          if (!link) {
            return null;
          }

          const { image, title } = link || {};
          const itemProps = getItemProps({ item: block, index, ...getSelectItemId(id, 'li') });

          return (
            <li
              key={entryName!}
              value={title!}
              {...itemProps}
              onClick={(e) => {
                itemProps.onClick(e);
                setSelectedItem(block);
              }}
              className={styles.phoneSupportCountriesDropdownMenuOption}
            >
              <DataButton
                link={link}
                className={styles.phoneSupportCountriesDropdownMenuOptionButton}
              >
                {image && (
                  <WebpImage
                    image={EXTERNAL_WEBP_IMAGE}
                    alt={image.title!}
                    src={image.url!}
                    pictureClassName={styles.phoneSupportCountriesDropdownMenuOptionPicture}
                    className={styles.phoneSupportCountriesDropdownMenuOptionImage}
                  />
                )}
                {title && (
                  <span className={styles.phoneSupportCountriesDropdownMenuOptionTitle}>
                    {title}
                  </span>
                )}
              </DataButton>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PhoneSupportSelect;
