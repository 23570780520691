import React from 'react';
import { useSelector } from 'react-redux';

import { MiddleBarInteraction } from 'components/Header/MiddleBar/Interaction';
import { Store } from 'app-redux/types/storeTypes';
import { MiddleBarInteractionContainerInterface } from 'components/Header/MiddleBar/Interaction/declarations';

const MiddleBarInteractionContainer: React.FC<MiddleBarInteractionContainerInterface> = ({
  authorization,
  searchBox,
}) => {
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const user = useSelector((store: Store) => store.server.auth.user);

  return (
    <MiddleBarInteraction
      user={user}
      isAuthenticated={isAuthenticated}
      authorization={authorization}
      searchBox={searchBox}
    />
  );
};

export default MiddleBarInteractionContainer;
