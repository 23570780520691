import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { Block } from 'src/__generated__/graphqlTypes';
import { getRightPsychicsProxy } from 'src/api/psychicApi';
import { usePsychicStatuses } from 'src/firebase/firebase.hook';
import type { Store } from 'app-redux/types/storeTypes';
import { setFirebaseRequiredState } from 'app-redux/actions/appActions';
import PsychicsSetMediumSizeImages from 'components/Sections/PsychicsSetMediumSizeImagesClone/PsychicsSetMediumSizeImages';
import type { SectionComponentInterface } from 'types/componentTypes';
import { PaginationInfo, RightPsychic } from 'types/objectTypes';
import {
  CommonContentfulSlug,
  LoadingMode,
  PaginationType,
  PsychicCardAppearance,
} from 'constants/enums';
import { IntersectionObserverInit, IntersectionObserverWrapper } from 'lib/intersectionObserver';
import { useCommonContentfulBlocks } from 'lib/shared.hook';
import { Logger } from 'lib/logger';
import { useBootChatSolution } from 'entities/PsychicCtaButton';
import { HOMEPAGE_SLUG } from 'constants/constants';

import { requestBody } from './lib/constants';
import { useSortIdQueryParam } from './lib/hooks';
import { getSortIdCookie, setSortIdCookie } from './lib';

const PsychicsSetMediumSizeImagesContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const [block] = blocks as Array<Block & { psychics: Array<RightPsychic> }>;
  const [psychics, setPsychics] = useState<Array<RightPsychic>>(block.psychics);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
    currentPageIndex: 0,
    totalPages: 0,
    isNextPageEnabled: false,
    isPreviousPageEnabled: false,
    psychics: [],
  });
  const dispatch = useDispatch();
  const sectionRef = useRef<HTMLElement>(null);
  const idList: Array<number> = psychics?.flatMap((psychic) => psychic.extId);
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const pages = useSelector((store: Store) => store.server.page.pages);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const { loader } = pages[slug];
  const prevIsAuthenticated = useRef<boolean | null>(null);

  const {
    content,
    topDivider,
    bottomDivider,
    commonPageMaxWidth,
  } = useCommonContentfulBlocks<CommonContentfulSlug, Block>(blocks);
  const bootStatus = useBootChatSolution();
  const sortid = useSortIdQueryParam(slug);

  const {
    itemsAmount,
    loadingMode = LoadingMode.LAZY,
    paginationType,
    maxPaginationPageAmount = 0,
    apiData,
  } = extraData || {};

  usePsychicStatuses(idList, setPsychics);
  const setPsychicsFromRemote = useCallback(async () => {
    let currentRequestBody = { ...requestBody };

    if (itemsAmount) {
      if (maxPaginationPageAmount) {
        currentRequestBody.PageSize = maxPaginationPageAmount * itemsAmount;
      } else {
        currentRequestBody.PageSize = itemsAmount;
      }
    }

    if (apiData) {
      const filteredApiData = Object.fromEntries(Object.entries(apiData).filter(
        ([, value]) => value !== null,
      ));

      currentRequestBody = { ...currentRequestBody, ...filteredApiData };
    }

    const sortingIdCookie = getSortIdCookie();

    if (sortid || sortingIdCookie) {
      currentRequestBody.SortId = sortid || sortingIdCookie;
    }

    const {
      psychics,
      currentPageIndex,
      hasPrevPage,
      sortResult,
    } = await getRightPsychicsProxy(currentRequestBody);

    const isHomepage = slug === HOMEPAGE_SLUG;
    const shouldModifySortingCookie = isHomepage && sortResult && sortid !== sortingIdCookie;

    if (shouldModifySortingCookie) {
      setSortIdCookie(sortid);
    }

    setPsychics(psychics);
    const startIdx = currentPageIndex * (itemsAmount || 0);
    const endIndx = startIdx + (itemsAmount || 0);
    const isPageInRange = currentPageIndex < maxPaginationPageAmount - 1;
    const isEndIndexWithinAvailablePsychics = endIndx < psychics.length;
    const isNextPageEnabled = isPageInRange && isEndIndexWithinAvailablePsychics;
    setPaginationInfo({
      currentPageIndex,
      totalPages: Math.ceil(psychics.length / (itemsAmount || psychics.length || 1)),
      isNextPageEnabled,
      isPreviousPageEnabled: hasPrevPage,
      psychics: psychics.slice(startIdx, endIndx),
    });
    const shouldUseFirebase = extraData
      && extraData.psychicCardAppearance !== PsychicCardAppearance.SIMPLE
      && extraData.psychicCardAppearance !== PsychicCardAppearance.SIMPLE_ADDITIONAL;

    if (shouldUseFirebase) {
      dispatch(setFirebaseRequiredState(true));
    }
  }, [extraData]);

  const changePage = useCallback(async (paginationStep: number) => {
    const { currentPageIndex = 0 } = paginationInfo || {};
    const newPageIndex = currentPageIndex + paginationStep;
    const startIdx = newPageIndex * (itemsAmount || 0);
    const endIndx = startIdx + (itemsAmount || 0);
    const isPageInRange = newPageIndex < maxPaginationPageAmount - 1;
    const isEndIndexWithinAvailablePsychics = endIndx < psychics.length;
    const isNextPageEnabled = isPageInRange && isEndIndexWithinAvailablePsychics;

    if (paginationType === PaginationType.NUMBERS) {
      setPaginationInfo({
        currentPageIndex: newPageIndex,
        totalPages: Math.ceil(psychics.length / (itemsAmount || psychics.length || 1)),
        isNextPageEnabled,
        isPreviousPageEnabled: newPageIndex > 0,
        psychics: psychics.slice(startIdx, endIndx),
      });
    } else {
      setPaginationInfo({
        currentPageIndex: newPageIndex,
        isNextPageEnabled,
        psychics: [...paginationInfo.psychics, ...psychics.slice(startIdx, endIndx)],
      });
    }
  }, [
    itemsAmount,
    maxPaginationPageAmount,
    paginationType,
    psychics,
    paginationInfo,
  ]);

  useEffect(() => {
    (async () => {
      if (psychics?.length === 0 && isAuthenticated !== prevIsAuthenticated.current) {
        await setPsychicsFromRemote();
        prevIsAuthenticated.current = isAuthenticated;
      } else if (sortid) {
        const sortingIdCookie = getSortIdCookie();

        if (sortid !== sortingIdCookie) {
          await setPsychicsFromRemote();
        }
      }
    })();
  }, [isAuthenticated, psychics, setPsychicsFromRemote, sortid]);

  useEffect(() => {
    if (loadingMode === LoadingMode.EAGER) {
      if (itemsAmount !== psychics?.length) {
        (async () => {
          try {
            await setPsychicsFromRemote();
          } catch (e) {
            Logger.error(e);
          }
        })();
      }

      return;
    }

    if (!sectionRef.current) {
      (async () => {
        try {
          await setPsychicsFromRemote();
          dispatch(setFirebaseRequiredState(true));
        } catch (e) {
          Logger.error(e);
        }
      })();

      return;
    }

    const intersectionHandler = async (
      entries: Array<IntersectionObserverEntry>,
      observer: IntersectionObserver,
    ) => {
      const handleSuccess = async () => {
        await setPsychicsFromRemote();
        dispatch(setFirebaseRequiredState(true));
        observer.unobserve(sectionRef.current!);
      };
      const observed: Array<any> = [];
      entries
        .forEach((entry) => {
          if (entry.isIntersecting) {
            try {
              observed.push(handleSuccess());
            } catch (e) {
              Logger.error(e);
            }
          }
        });

      await Promise.all(observed);
    };

    const options: IntersectionObserverInit = {
      rootMargin: '300px',
    };

    const observer = IntersectionObserverWrapper.getInstance(intersectionHandler, options);

    observer?.observe(sectionRef.current);
  }, [setPsychicsFromRemote]);

  return (
    <PsychicsSetMediumSizeImages
      loader={loader}
      bootStatus={bootStatus}
      bgColor={bgColor}
      extraData={extraData}
      psychics={psychics}
      sectionRef={sectionRef}
      viewerDevice={viewerDevice}
      content={content as any}
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      changePage={changePage}
      commonPageMaxWidth={commonPageMaxWidth}
      paginationInfo={paginationInfo}
    />
  );
};

export default PsychicsSetMediumSizeImagesContainer;
