import React, {
  ChangeEvent,
  FC,
  FormEvent,
} from 'react';
import find from 'lodash/find';

import type { Text } from 'src/__generated__/graphqlTypes';
import { DataButton } from 'components/Shared/SharedComponents';
import { IdNameType, ValidationAndValue } from 'types/objectTypes';
import { commonEmailRegexp, commonPhoneRegexp } from 'lib/regexp.service';
import { TEXTAREA_MAX_LENGTH } from 'constants/constants';

import styles from './Form.module.scss';
import FeedbackFormAutocomplete from './Autocomplete';
import ZendeskModal from './ZendeskModal';
import FeedbackFormSelect from './Select';
import { FeedbackFormInput, FeedbackFormTextarea } from './Input';

import { FeedbackFormInterfaceProps, ErrorHandler } from '../config/declarations';
import {
  checkInputDataInvalidity,
  findErrorIfNeeded,
  getCommonValidationAndError,
  getValidation,
} from '../lib';
import { FeedbackFormStateValue } from '../config/constants';

const FeedbackForm: FC<FeedbackFormInterfaceProps> = ({
  requestTypeOptions,
  title,
  button,
  modal,
  isModalVisible,
  countries,
  inputValues,
  setInputValues,
  searchInCountries,
  closeModal,
  handleSubmitForm,
  fetchCountries,
}) => {
  const getCountryValidationAndError = (
    item: ValidationAndValue<string | IdNameType<string>>,
    pattern?: RegExp | string | null,
    itemValue?: string | IdNameType<string>,
  ) => {
    const { value, isValid } = item;
    const currentValue = itemValue || value;
    const country = find(countries, (country) => country.text === currentValue);
    const validation = getValidation(country?.text || '', pattern);
    const isValidOrNull = (isValid !== null) ? validation : null;
    const { error } = findErrorIfNeeded(item, isValidOrNull, !validation);

    return { error, validation, isValid: isValidOrNull };
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    errorHandler: ErrorHandler = getCommonValidationAndError,
    pattern?: RegExp | string | null,
  ) => {
    const { name, value } = e?.target || {};
    const inputItem: ValidationAndValue<any> = inputValues[name];
    const { error, isValid, validation } = errorHandler(inputItem, pattern, value);

    const values = {
      ...inputValues,
      [name]: {
        ...inputItem,
        value,
        isValid: (isValid !== null) ? validation : null,
        error,
      },
    };
    setInputValues(values);
  };

  const onBlur = (
    name: FeedbackFormStateValue,
    errorHandler: ErrorHandler = getCommonValidationAndError,
    pattern?: RegExp | string | null,
  ) => setInputValues((prev) => {
    const prevItem = prev[name];
    const { validation, error } = errorHandler(prevItem, pattern);

    return ({
      ...prev,
      [name]: {
        ...prevItem,
        isValid: validation,
        error,
      },
    });
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isInvalid = checkInputDataInvalidity(inputValues, setInputValues);

    if (!isInvalid) {
      handleSubmitForm(inputValues);
    }
  };

  const setSelectValue = (
    name: FeedbackFormStateValue,
    value: string | IdNameType<string>,
  ) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      [name]: {
        ...inputValues[name],
        isValid: true,
        value,
      },
    }));
  };

  const setRequestType = (requestType: Text) => setSelectValue(
    FeedbackFormStateValue.REQUEST_TYPE,
    { id: requestType.slug!, name: requestType.text! },
  );

  const setCountry = (country: Text) => setSelectValue(
    FeedbackFormStateValue.COUNTRY,
    country.text!,
  );

  return (
    <form
      id={styles.form}
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <fieldset
        form={styles.form}
        className={styles.formFieldset}
      >
        <legend className={styles.formFieldsetTitle}>
          {title}
        </legend>
        <FeedbackFormInput
          name={FeedbackFormStateValue.NAME}
          inputData={inputValues.name}
          onChange={(e) => handleInputChange(e)}
          onBlur={() => onBlur(FeedbackFormStateValue.NAME)}
        />
        <FeedbackFormInput
          name={FeedbackFormStateValue.EMAIL}
          inputData={inputValues.email}
          onChange={(e) => handleInputChange(
            e,
            getCommonValidationAndError,
            commonEmailRegexp,
          )}
          onBlur={() => onBlur(
            FeedbackFormStateValue.EMAIL,
            getCommonValidationAndError,
            commonEmailRegexp,
          )}
        />
        <FeedbackFormInput
          name={FeedbackFormStateValue.PHONE_NUMBER}
          inputData={inputValues.phoneNumber}
          onChange={(e) => handleInputChange(
            e,
            getCommonValidationAndError,
            commonPhoneRegexp,
          )}
          onBlur={() => onBlur(
            FeedbackFormStateValue.PHONE_NUMBER,
            getCommonValidationAndError,
            commonPhoneRegexp,
          )}
          className={styles.formFieldsetDivInputSmall}
        />
        <FeedbackFormAutocomplete
          name={FeedbackFormStateValue.COUNTRY}
          title={inputValues.country.placeholder!}
          selectOptions={countries}
          inputData={inputValues.country}
          setInputValue={setCountry}
          onBlur={() => onBlur(FeedbackFormStateValue.COUNTRY, getCountryValidationAndError)}
          onClick={fetchCountries}
          onChange={(e) => {
            handleInputChange(e, getCountryValidationAndError);
            searchInCountries(e.target.value);
          }}
          className={styles.formFieldsetDivInputSmall}
        />
        <FeedbackFormSelect
          title={inputValues.requestType.placeholder!}
          selectOptions={requestTypeOptions!}
          inputData={inputValues.requestType}
          onBlur={() => onBlur(FeedbackFormStateValue.REQUEST_TYPE)}
          setInputValue={setRequestType}
        />
        <FeedbackFormTextarea
          name={FeedbackFormStateValue.MESSAGE}
          inputData={inputValues.message}
          onChange={(e) => handleInputChange(e)}
          onBlur={() => onBlur(FeedbackFormStateValue.MESSAGE)}
          className={styles.formFieldsetDivInputBig}
          maxLength={TEXTAREA_MAX_LENGTH}
        />
      </fieldset>
      {button && (
        <DataButton
          type="submit"
          form={styles.form}
          className={styles.formSubmit}
        >
          {button.title}
        </DataButton>
      )}
      <ZendeskModal
        modal={modal}
        isModalVisible={isModalVisible}
        closeModal={closeModal}
      />
    </form>
  );
};

export default FeedbackForm;
