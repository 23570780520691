import { ViewerDevice } from 'constants/enums';
import {
  Block,
  Input,
  Link,
} from 'src/__generated__/graphqlTypes';

export interface PhoneSupportInterface {
  title: string;
  input: Input;
  firstCountry: Block;
  smsButton?: Block;
  viewerDevice: ViewerDevice;
  countries: Array<Block>;
}

export interface PhoneSupportSelectInterface {
  id: Input['sys']['id'];
  title?: string;
  className?: string;
  options: Array<Block>;
  placeholder: string;
  signImageSize: number;
  setSelectedItem: (item: Block) => void;
}

export enum PhoneSupportContainerSlug {
  INPUT = 'input',
  FIRST_COUNTRY = 'firstCountry',
  COUNTRIES = 'countries',
}

export interface CountriesSelectInterface {
  input: Input;
  countries: Array<Block>;
  setCurrentCountry: (country: Block) => void;
}

export interface DisplayedCountryInterface {
  currentCountry: Block;
}

export interface PhoneSupportReduceType {
  [PhoneSupportContainerSlug.INPUT]: Input;
  [PhoneSupportContainerSlug.FIRST_COUNTRY]: Block;
  [PhoneSupportContainerSlug.COUNTRIES]: Array<Block>;
}

export interface PhoneNumberInterface {
  link: Block['link'];
  country: Link['title'];
}
