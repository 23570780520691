import React from 'react';
import cn from 'classnames';

import { FooterDesktopNavBarItemInterface } from 'types/componentTypes';
import { Link } from 'src/__generated__/graphqlTypes';
import { DataLink } from 'components/Shared/SharedComponents';
import { DATA_NAVIGATION_ATTRIBUTE_NAME } from 'constants/constants';

const DesktopNavBarLinkItem: React.FC<FooterDesktopNavBarItemInterface> = ({
  navItem,
  image,
  classes,
  children,
  isHighlight,
  navigationLocation,
  itemEqualWithCurrentPathName,
}) => {
  const link = navItem as Link;
  const isCurrentRouteItem = itemEqualWithCurrentPathName === link.entryName;
  const string = children
    ? (
      <>
        <span className={classes?.span}>
          {link.title}
        </span>
        <span
          style={{ backgroundImage: `url(${image.url})` }}
          className={(isCurrentRouteItem && classes?.imgCurrent)
            ? classes.imgCurrent
            : classes?.img}
        />
      </>
    )
    : link.title;
  const aClasses = cn(
    classes?.link,
    isHighlight && classes?.linkBold,
    (isCurrentRouteItem && classes?.aCurrent) && classes.aCurrent,
  );

  return (
    <li key={link.entryName} className={classes?.li}>
      <DataLink
        link={link}
        href={link.src!}
        className={aClasses}
        {...{ [DATA_NAVIGATION_ATTRIBUTE_NAME]: navigationLocation }}
      >
        {string}
      </DataLink>
      {children && (
        <ul className={classes?.innerUl}>
          {children}
        </ul>
      )}
    </li>
  );
};

export default DesktopNavBarLinkItem;
