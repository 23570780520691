import {
  FC,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { getReadingsCountProxy } from 'src/api/customerApi';
import { AccountSummary } from 'components/Header/MiddleBar/Interaction/AccountSummary';
import { Store } from 'app-redux/types/storeTypes';
import { setLoadingState } from 'app-redux/actions/appActions';
import { AccountSummaryContainerInterface } from 'components/Header/MiddleBar/Interaction/AccountSummary/declarations';
import { signOut } from 'lib/auth.service';
import { Logger } from 'lib/logger';

const AccountSummaryContainer: FC<AccountSummaryContainerInterface> = ({
  isClickedFirstTime,
  isShownForm,
  accountSummary,
  setShownFormState,
}) => {
  const userDetails = useSelector((store: Store) => store.server.auth.user);
  const oaPlatform = useSelector((store: Store) => store.server.app.oaPlatform);
  const [readingsCount, setReadingsCount] = useState<number>(0);
  const [customerDetails, setCustomerDetails] = useState<any>({});
  const [isDataLoading, setDataLoadingState] = useState<boolean>(false);
  const dispatch = useDispatch();
  const router = useCustomRouter();

  const onSignOut = async () => {
    try {
      dispatch(setLoadingState(true));
      await signOut(userDetails, oaPlatform);
      setShownFormState(false);
      const url = router.asPath;
      router.replace(`${url}?t=${new Date().getTime()}`, url, { keepQuery: true, withReload: true });
    } catch (e) {
      Logger.error(e);
    }
  };

  useEffect(() => {
    if (isClickedFirstTime && userDetails?.custId) {
      (async () => {
        try {
          dispatch(setLoadingState(true));
          const { dollarBalance, karmaPoints, custId } = userDetails;
          const readingsCount = await getReadingsCountProxy(custId, new Date().toDateString());
          setReadingsCount(readingsCount);

          setCustomerDetails({ dollarBalance, karmaPoints });
        } catch (e) {
          Logger.error(e);
        } finally {
          dispatch(setLoadingState(false));
          setDataLoadingState(true);
        }
      })();
    }
  }, [isClickedFirstTime, userDetails, dispatch]);

  if (!isDataLoading) return null;

  return (
    <AccountSummary
      readingsCount={readingsCount}
      customerDetails={customerDetails}
      isShownForm={isShownForm}
      accountSummary={accountSummary}
      onSignOut={onSignOut}
      isKarmaMember={userDetails?.isKarmaMember!}
    />
  );
};

export default AccountSummaryContainer;
