import { IS_CLIENT_SIDE } from 'constants/constants';
import { ShouldCall } from 'lib/decorators';
import { DataLayerEvent } from 'types/objectTypes';

const shouldFireEvents = IS_CLIENT_SIDE
  && Boolean(window.dataLayer)
  && Array.isArray(window.dataLayer);

export class GTM {
  @ShouldCall(shouldFireEvents)
  public static sendEvent(event: string, rest: Omit<DataLayerEvent, 'event'>) {
    window.dataLayer!.push({ event, ...rest });
  }
}
