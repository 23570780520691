import { FC, useContext } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { useLoaderImage } from 'src/shared/lib/loader/hooks';
import {
  getItemsLayoutClass,
  getPsychicCardAppearanceName,
  getPsychicsLayoutStyle,
} from 'src/shared/lib/psychics/styles';
import type { Store } from 'app-redux/types/storeTypes';
import { Loader } from 'components/Loader';
import { ItemsLayout, PsychicCardAppearance } from 'constants/enums';
import { getItemPositionForGa } from 'lib/sharedMethods.service';
import type { RightPsychic } from 'types/objectTypes';
import { useBootChatSolution } from 'entities/PsychicCtaButton';

import styles from './styles.module.scss';
import PsychicCard from './PsychicCard';
import PsychicDefaultCard from './PsychicDefaultCard';

import type { ICardsList, IPsychicBlock } from '../config/declarations';
import { IdProvider } from '../service/context';
import { constructId } from '../service';
import { usePsychics } from '../service/api-handler';

/* Nested Component */
const CardsList: FC<ICardsList> = ({
  psychics,
  psychicFrame,
  extraData,
}) => {
  useBootChatSolution();
  const bootStatus = useSelector((store: Store) => store.client.app.chatBootStatus);

  const cards = psychics.map((psychic, i) => (
    <PsychicCard
      key={psychic.extId}
      bootStatus={bootStatus}
      psychic={psychic}
      psychicFrame={psychicFrame}
      positionInArray={getItemPositionForGa<RightPsychic>(psychics, i)}
      extraData={extraData}
    />
  ));

  if (psychics.length < 3 && psychicFrame.defaultCard) {
    cards.push(<PsychicDefaultCard
      key={psychicFrame.sys.id}
      defaultCard={psychicFrame.defaultCard}
      extraData={extraData}
    />);
  }

  return <>{cards}</>;
};

/* Main Component */
const PsychicsBlock: FC<IPsychicBlock> = ({
  sectionRef,
  psychicFrame,
  extraData,
}) => {
  const {
    itemsLayout = ItemsLayout.ROW,
    psychicCardAppearance = PsychicCardAppearance.SIMPLE_VERTICAL,
  } = extraData;
  const { psychics } = usePsychics(extraData, sectionRef);
  const id = useContext(IdProvider);
  const loader = useLoaderImage();

  if (!psychics?.length) {
    return (
      <div
        id={constructId(id, '-loader')}
        className={styles.loader}
      >
        <Loader
          image={loader!}
          isVisible
        />
      </div>
    );
  }

  if (!psychicFrame) {
    return null;
  }

  const itemsLayoutName = getItemsLayoutClass(psychicCardAppearance, itemsLayout);
  const psychicCardAppearanceName = getPsychicCardAppearanceName(psychicCardAppearance);
  const style = getPsychicsLayoutStyle(itemsLayout, psychicCardAppearance, psychics);

  return (
    <ul
      style={style}
      id={constructId(id, '-psychics')}
      className={cn(
        styles.psychics,
        styles[`psychics${psychicCardAppearanceName}${itemsLayoutName}`],
      )}
    >
      <CardsList
        psychics={psychics}
        extraData={extraData}
        psychicFrame={psychicFrame}
      />
    </ul>
  );
};

export default PsychicsBlock;
