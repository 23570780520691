import { Status } from 'constants/enums';
import { Link, Psychic } from 'src/__generated__/graphqlTypes';
import { BootStatus } from 'types/componentTypes';
import {
  RatePerMinuetType,
  RightPsychic,
  SectionExtraDataType,
} from 'types/objectTypes';

export interface ExponeaPsychicProfileInterface extends BootStatus {
  bgColor?: string;
  extraData?: SectionExtraDataType;
  psychic: RightPsychic;
  psychicFrame: Psychic;
}

export type HeartImage = {
  button: Link;
  hint: Text;
};

export enum CardBarHeartButtonSlugEnum {
  BUTTON = 'button',
  HINT_REMOVE = 'hint-remove',
  HINT_ADD = 'hint-add',
}

export interface ProfileInteractionInterface extends BootStatus {
  psychicFrame: Psychic;
  psychic: RightPsychic;
}

export interface HeartImageComponentInterface {
  contentfulData: Psychic;
  isFavorite?: boolean;
}

export interface ProfileInfoInterface {
  psychicFrame: Psychic;
  psychic: RightPsychic;
}

export interface EstimationComponentInterface {
  estimatedWaiting: Psychic['estimatedWaiting'];
  estimatedWaitTime: number;
}

export interface QueueComponentInterface {
  queue: Psychic['queue'];
  peopleInQueue: number;
}

export interface PriceComponentInterface {
  ratePerMinute: RatePerMinuetType;
  ratePerMinuteText: Psychic['ratePerMinute'];
}

export interface RatingComponentInterface {
  overallScore: RightPsychic['overallScore'];
  starImage: Psychic['starImage'];
  responses: RightPsychic['responses'];
}

export interface StatusComponentInterface {
  psychic: RightPsychic;
  psychicFrame: Psychic;
  chatStatus: Status;
  phoneStatus: Status;
  statusText: Psychic['status'];
}
