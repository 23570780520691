export enum InputSlug {
  NAME_INPUT = 'nameInput',
  EMAIL_INPUT = 'emailInput',
  PHONE_NUMBER_INPUT = 'phoneNumberInput',
  COUNTRY_INPUT = 'countryInput',
  REQUEST_TYPE_INPUT = 'requestTypeInput',
  MESSAGE_INPUT = 'messageInput',
  MODAL = 'modal',
}

export const feedbackFormSlugs = {
  'name-input': InputSlug.NAME_INPUT,
  'email-input': InputSlug.EMAIL_INPUT,
  'phone-number-input': InputSlug.PHONE_NUMBER_INPUT,
  'country-input': InputSlug.COUNTRY_INPUT,
  'request-type-input': InputSlug.REQUEST_TYPE_INPUT,
  'message-input': InputSlug.MESSAGE_INPUT,
  modal: InputSlug.MODAL,
};

export enum FeedbackFormStateValue {
  NAME = 'name',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  COUNTRY = 'country',
  REQUEST_TYPE = 'requestType',
  MESSAGE = 'message',
}
