import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { FC } from 'react';
import cn from 'classnames';

import styles from 'src/components/Sections/ContactUsHelpCenter/HelpCenter.module.scss';
import { Block, Link } from 'src/__generated__/graphqlTypes';
import classes from 'src/styles/_commonClasses.module.scss';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  CommonRichText,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { getImagesWithLimitedSize } from 'lib/image.service';
import { Heading, ImageFitMode } from 'constants/enums';
import HoroscopeNewsletters from 'components/Sections/HoroscopeNewsletters';
import { mapBlocksHeadingWithId } from 'lib/richTextMappers';
import {
  ContactUsHelpCenterInterface,
  HelpCenterImageSizeRatioSlug as Slug,
  TitleInterface,
  GeneralInfoImageInterface,
  GeneralInfoInterface,
} from 'components/Sections/ContactUsHelpCenter/declarations';

const getParsersConfig = (id?: Block['blockId']): RichTextParsersConfig => {
  const getHeadingClass = (specificClass: string) => cn(
    styles.helpCenterTitleHeading,
    specificClass,
  );

  return {
    [BLOCKS.HEADING_1]: {
      classNames: getHeadingClass(styles.helpCenterTitleHeadingH1),
      mapper: mapBlocksHeadingWithId(id!, Heading.FIRST),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHeadingClass(styles.helpCenterTitleHeadingH2),
      mapper: mapBlocksHeadingWithId(id!),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: styles.helpCenterTitleParagraph,
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  };
};

/* Nested component */
const Title: FC<TitleInterface> = ({ title, content, id }) => {
  if (!title && !content) {
    return null;
  }

  return (
    <div className={styles.helpCenterTitle}>
      {title && (
        <CommonRichText
          content={title}
          parsersConfig={getParsersConfig(id)}
        />
      )}
      {content && (
        <CommonRichText
          content={content}
          parsersConfig={getParsersConfig(id)}
        />
      )}
    </div>
  );
};

/* Nested component */
const GeneralInfoImage: FC<GeneralInfoImageInterface> = ({
  image,
  isMobileViewWidth,
  mobileViewMaxWidth,
}) => {
  if (!image) {
    return null;
  }

  const entryHeight = Math.ceil(image.height! * Slug.ENTRY_IMAGE);
  const entryWidth = Math.ceil(image.width! * Slug.ENTRY_IMAGE);
  const mobileHeight = Math.ceil(entryHeight * Slug.MOBILE);
  const mobileWidth = Math.ceil(entryWidth * Slug.MOBILE);

  const widthLimit = getImagesWithLimitedSize([
    {
      height: mobileHeight,
      width: mobileWidth,
    },
    {
      height: entryHeight,
      width: entryWidth,
      isMinWidth: true,
    },
  ],
  {
    fit: ImageFitMode.SCALE,
    media: mobileViewMaxWidth,
  });

  const height = isMobileViewWidth ? mobileHeight : entryHeight;
  const width = isMobileViewWidth ? mobileWidth : entryWidth;

  return (
    <WebpImage
      image={image}
      className={styles.helpCenterGeneralInfoCardImage}
      additionalProps={{ h: height, w: width }}
      height={height}
      width={width}
      widthLimit={widthLimit}
    />
  );
};

/* Nested component */
const GeneralInfo: FC<GeneralInfoInterface> = ({
  helpLinks,
  isMobileViewWidth,
  mobileViewMaxWidth,
}) => {
  if (!helpLinks?.length) {
    return null;
  }

  return (
    <ul className={styles.helpCenterGeneralInfo}>
      {
        helpLinks.map((link) => {
          const { image, entryName } = link;
          const cardClasses = cn(
            styles.helpCenterGeneralInfoCard,
            styles.helpCenterGeneralInfoCardBorder,
          );

          return (
            <li key={entryName} className={cardClasses}>
              <DataLink
                link={link}
                className={styles.helpCenterGeneralInfoCardLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GeneralInfoImage
                  image={image!}
                  isMobileViewWidth={isMobileViewWidth}
                  mobileViewMaxWidth={mobileViewMaxWidth}
                />
                <span className={styles.helpCenterGeneralInfoCardLinkTitle}>
                  {link.title}
                </span>
              </DataLink>
            </li>
          );
        })
      }
    </ul>
  );
};

/* Main component */
const HelpCenter: FC<ContactUsHelpCenterInterface> = ({
  title,
  generalInfo,
  slug,
  bgColor,
  newsletters,
  contactUsTabletMaxWidth,
  isMobileViewWidth,
  mobileViewMaxWidth,
}) => {
  const { richTitle, content, blockId } = title;
  const helpLinks = generalInfo.contentTypesCollection?.items as Array<Link> | undefined;

  return (
    <section className={styles.helpCenter} style={{ background: bgColor }}>
      <Title
        title={richTitle}
        content={content}
        id={blockId}
      />
      <GeneralInfo
        isMobileViewWidth={isMobileViewWidth}
        helpLinks={helpLinks}
        mobileViewMaxWidth={mobileViewMaxWidth}
      />
      <div className={styles.helpCenterNewsletters}>
        <HoroscopeNewsletters
          block={newsletters}
          slug={slug}
          tabletMediaMaxWidth={+contactUsTabletMaxWidth}
        />
      </div>
    </section>
  );
};
export default HelpCenter;
