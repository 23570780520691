/* eslint-disable no-underscore-dangle */
import { FC } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { Link } from 'src/__generated__/graphqlTypes';
import { DataButton, PsychicRate } from 'components/Shared/SharedComponents';
import { PsychicStatusIndicator, PsychicStatusText } from 'entities/PsychicStatus';
import { HeartImage } from 'entities/PsychicHeartImage';

import styles from './styles.module.scss';

import { BarInterface, ButtonState } from '../config/declarations';

const Bar: FC<BarInterface> = ({
  psychic,
  contentfulData,
  status,
  ratePerMinute,
  isCollapsedExtraInfo,
  onClickArrow,
}) => {
  const applyArrow = () => {
    const extraInfoToggleButtons = contentfulData.extraInfoButtonsCollection?.items as Array<Link>;
    const defaultReturn = (
      <DataButton
        className={styles.cardBarExtraInfo}
        onClick={onClickArrow}
        onKeyPress={onClickArrow}
      />
    );

    if (!extraInfoToggleButtons?.length) {
      return defaultReturn;
    }

    const buttonState = isCollapsedExtraInfo
      ? ButtonState.OPEN
      : ButtonState.CLOSE;
    const extraInfoLink = extraInfoToggleButtons.find((button) => button.slug === buttonState);
    const arrowClass = isCollapsedExtraInfo
      ? styles.cardBarExtraInfoAsset
      : styles.cardBarExtraInfoAssetClose;

    if (!extraInfoLink) {
      return defaultReturn;
    }

    const { image } = extraInfoLink;

    return (
      <DataButton
        link={extraInfoLink}
        className={cn(styles.cardBarExtraInfo, classes.cursorPointer)}
        onClick={onClickArrow}
        onKeyPress={onClickArrow}
      >
        {image && (
          <i
            className={arrowClass}
            style={{ backgroundImage: `url(${image.url!})` }}
          />
        )}
      </DataButton>
    );
  };

  return (
    <div className={styles.cardBar}>
      <div className={styles.cardBarStatus}>
        <div className={styles.cardBarStatusHead}>
          <PsychicStatusIndicator
            status={status}
            className={styles.cardBarStatusHeadIndicator}
          />
          <span className={styles.cardBarStatusHeadTitle}>{contentfulData.status}</span>
        </div>
        <PsychicStatusText
          status={status}
          className={styles.cardBarStatusText}
          offsetClass={styles.cardBarStatusTextOffset}
          psychic={psychic}
          psychicFrame={contentfulData}
          wrapperClass={styles.cardBarStatusTextWrapper}
        />
      </div>
      <div className={styles.cardBarRate}>
        <span className={styles.cardBarRateTitle}>
          {contentfulData.ratePerMinute}
        </span>
        <PsychicRate
          pricePerMinute={ratePerMinute.price}
          priceWithDiscountPerMinute={ratePerMinute.discount}
          classNames={{
            wrapper: styles.cardBarRatePrice,
            common: styles.cardBarRatePriceCommon,
            commonWithDiscount: styles.cardBarRatePriceCrossed,
            discount: styles.cardBarRatePriceDiscount,
          }}
        />
      </div>
      <HeartImage
        contentfulData={contentfulData}
        psychic={psychic}
        className={styles.cardBarHeartImage}
        hintClassName={styles.cardBarHeartImageHint}
        hintArrowClassName={styles.cardBarHeartImageHintArrow}
      />
      {applyArrow()}
    </div>
  );
};

export default Bar;
