import { FC } from 'react';
import cn from 'classnames';

import { DataLink } from 'components/Shared/SharedComponents';
import styles from 'components/Sections/ContactUsGetInTouch/PhoneSupport/PhoneSupport.module.scss';
import { CTMNumber, CUSTOMER_CARE_TEAM } from 'entities/CTMPhone';
import { PhoneNumberInterface } from 'components/Sections/ContactUsGetInTouch/PhoneSupport/declarations';
import { USA_COUNTRY_NAME } from 'constants/constants';

const common = {
  className: cn(
    styles.phoneSupportCountryPhoneTel,
    styles.phoneSupportCountryPhoneMarginLeft,
  ),
};

const PhoneNumber: FC<PhoneNumberInterface> = ({ link, country }) => {
  if (!link?.src) {
    return null;
  }

  const telephone = link.src.substring(4);

  if (country?.toUpperCase() !== USA_COUNTRY_NAME
  || link.slug !== CUSTOMER_CARE_TEAM) {
    return (
      <DataLink
        link={link}
        {...common}
      >
        {telephone}
      </DataLink>
    );
  }

  return (
    <CTMNumber
      number={telephone}
      {...common}
    />
  );
};

export default PhoneNumber;
