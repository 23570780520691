import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

import {
  BootState,
  PresentationMode,
  SdkEvents,
  View,
} from 'common-chat-components/enums';
import { setUser } from 'app-redux/actions/authActions';
import { SESSION_COOKIE_NAME } from 'constants/constants';
import { Store } from 'app-redux/types/storeTypes';
import { UserType } from 'types/objectTypes';
import { sessionCookieOptions } from 'lib/session.service';
import { setBootStatus as setBootStatusRemote } from 'app-redux/actions/appActions';

import {
  checkIsValidChatToken,
  getChatBubbleButton,
  launchStatefulChat,
  tryToUpdateChatToken,
} from './chat';

let isChatSet = false;

export const useBootChatSolution = () => {
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const user = useSelector((store: Store) => store.server.auth.user);
  const dispatch = useDispatch();
  const [bootStatus, setBootStatus] = useState<BootState>(BootState.INVALID);
  const setBootStatusProxy = (bootStatus: BootState) => {
    setBootStatus(bootStatus);
    dispatch(setBootStatusRemote(bootStatus));
  };
  const updateSession = (user: UserType) => {
    cookies.set(SESSION_COOKIE_NAME, JSON.stringify(user), sessionCookieOptions);
    dispatch(setUser(user));
  };
  const removeSessionCookie = () => cookies
    .set(SESSION_COOKIE_NAME, '', { expires: new Date().getDate(), path: '/' });
  const waitForChatApp = useCallback((
    counter: number,
    timeout: Parameters<typeof clearTimeout>[0],
  ) => {
    if (counter > 15 || !isAuthenticated || isChatSet) {
      return clearTimeout(timeout);
    }

    const { ChatApp } = window;

    if (!ChatApp || typeof ChatApp !== 'function') {
      // eslint-disable-next-line no-param-reassign
      timeout = setTimeout(() => waitForChatApp(
        counter + 1,
        timeout,
      ), 100);

      return;
    }

    isChatSet = true;
    ChatApp!(SdkEvents.ON_LOGOUT, () => {
      setBootStatusProxy(BootState.STATELESS);
    });
    ChatApp!(
      SdkEvents.ON_CHAT_EVENT,
      { name: 'onSwitchToPhone' },
      ({ phoneNumber }) => window.open(`tel:${phoneNumber}`, '_parent'),
    );
    ChatApp!(
      SdkEvents.BOOT,
      {
        appId: '1002',
        chatHost: process.env.NEXT_PUBLIC_CHAT_SDK_URL,
        presentationMode: {
          type: PresentationMode.BUBBLE,
          view: View.CUSTOMER,
        },
      },
      async ({ bootStatus }) => {
        setBootStatusProxy(bootStatus);

        try {
          const isValid = checkIsValidChatToken(user);

          if (!isValid) {
            await tryToUpdateChatToken(user, updateSession);
          }

          if (bootStatus === BootState.STATEFUL) {
            const bubbleButton = getChatBubbleButton();
            launchStatefulChat(bubbleButton);
          }
        } catch (e) {
          removeSessionCookie();
        }
      },
    );
  }, [isAuthenticated]);

  useEffect(() => {
    if (isChatSet) {
      return;
    }

    const counter = 0;
    const timeout: any = null;
    waitForChatApp(counter, timeout);

    return () => clearTimeout(timeout);
  }, [waitForChatApp]);

  useEffect(() => () => { isChatSet = false; }, []);

  return bootStatus;
};
