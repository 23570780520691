import {
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import cn from 'classnames';

import { Asset } from 'src/__generated__/graphqlTypes';
import { PsychicComponentInterface } from 'types/componentTypes';
import { Status } from 'constants/enums';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { RatePerMinuetType } from 'types/objectTypes';
import { getPsychicCtaButtons, getStatus } from 'lib/psychic.service';
import { PsychicCardImage } from 'entities/PsychicCardImage';
import { PsychicCtaButtons } from 'entities/PsychicCtaButton';

import FloatInfo from './FloatInfo';
import Bar from './Bar';
import ExtraInfo from './ExtraInfo';
import styles from './styles.module.scss';

const Card: FC<PsychicComponentInterface> = ({
  psychicSkeleton,
  positionInArray,
  bootStatus,
  psychic,
}) => {
  const [isAnimationMounted, mountAnimation] = useState<boolean>(false);
  const [isCollapsedExtraInfo, setCollapse] = useState<boolean>(true);
  const chatStatus = (psychic.chatStatus?.toLowerCase() || Status.BUSY) as Status;
  const phoneStatus = (psychic.phoneStatus?.toLowerCase() || Status.BUSY) as Status;

  const toggleExtraInfo = useCallback(() => {
    if (!isAnimationMounted) mountAnimation(true);

    setCollapse((prev) => !prev);
  }, [isAnimationMounted]);

  const getAnimationClass = useCallback((): string => {
    if (isAnimationMounted) {
      return isCollapsedExtraInfo
        ? styles.cardRevealed
        : styles.cardCollapsed;
    }

    return '';
  }, [isAnimationMounted, isCollapsedExtraInfo]);

  const collapseStyle = useMemo(
    () => !isCollapsedExtraInfo && isAnimationMounted && styles.cardShift,
    [isCollapsedExtraInfo, isAnimationMounted],
  );

  const ratePerMinute: RatePerMinuetType = {
    discount: psychic.customerPrice,
    price: psychic.basePrice,
  };

  const cta = getPsychicCtaButtons(psychic, psychicSkeleton);

  return (
    <li className={styles.wrapper}>
      <div className={cn(styles.card, collapseStyle, getAnimationClass())}>
        <PsychicCardImage
          psychic={psychicSkeleton}
          image={EXTERNAL_WEBP_IMAGE}
          src={psychic.psychicImageUrl || psychicSkeleton.avatar?.url || ''}
          alt={psychic.psychicName}
          className={styles.cardImage}
          onClick={toggleExtraInfo}
          onKeyPress={toggleExtraInfo}
        />
        <FloatInfo
          starImage={psychicSkeleton.starImage as Asset || {}}
          extension={psychicSkeleton.extension || ''}
          tagLine={psychic.usp}
          responses={psychic.responses}
          overallScore={psychic.overallScore}
          extId={psychic.extId}
        />
        <Bar
          psychic={psychic}
          contentfulData={psychicSkeleton}
          ratePerMinute={ratePerMinute}
          status={getStatus({ chatStatus, phoneStatus })}
          isCollapsedExtraInfo={isCollapsedExtraInfo}
          onClickArrow={toggleExtraInfo}
        />
        <PsychicCtaButtons
          bootStatus={bootStatus}
          className={styles.interaction}
          chatButton={cta.chatButton}
          talkButton={cta.talkButton}
          callbackButton={cta.callbackButton}
          messageButton={cta.messageButton}
          psychicFrame={psychicSkeleton}
          psychic={psychic}
          shape="Rectangle"
        />
        <span className={styles.cardName} title={psychic.psychicName}>
          {psychic.psychicName}
        </span>
      </div>
      <ExtraInfo
        positionInArray={positionInArray!}
        contentfulData={psychicSkeleton}
        psychicName={psychic.psychicName}
        topics={psychic.specialities}
        totalReadings={psychic.totalReadings}
        startYear={psychic.serviceStartYear}
        estimatedTime={psychic.estimatedWaitTime}
        fullProfileLink={psychic.psychicBioURL}
        isAnimationMounted={isAnimationMounted}
        collapse={toggleExtraInfo}
        isCollapsed={isCollapsedExtraInfo}
      />
    </li>
  );
};

export default Card;
