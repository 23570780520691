import React from 'react';

import MiddleBar from 'components/Header/MiddleBar/MiddleBar';
import { MiddleBarContainerInterface } from 'components/Header/MiddleBar/declarations';

const MiddleBarContainer: React.FC<MiddleBarContainerInterface> = ({
  searchBox,
  navBar,
  authorization,
  mainLogo,
}) => (
  <MiddleBar
    mainLogo={mainLogo}
    navBar={navBar}
    authorization={authorization}
    searchBox={searchBox}
  />
);

export default MiddleBarContainer;
