import { FC } from 'react';
import cn from 'classnames';

import styles from 'components/Sections/ExponeaPsychicProfile/ExponeaPsychicProfile.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { capitalizeFirstLetter } from 'lib/text.service';
import { CommonSize } from 'constants/enums';
import { ExponeaPsychicProfileInterface } from 'components/Sections/ExponeaPsychicProfile/declarations';
import ProfileInteraction from 'components/Sections/ExponeaPsychicProfile/ProfileInteraction';
import ProfileInfo from 'components/Sections/ExponeaPsychicProfile/ProfileInfo';

const ExponeaPsychicProfile: FC<ExponeaPsychicProfileInterface> = ({
  psychic,
  bgColor,
  extraData,
  bootStatus,
  psychicFrame,
}) => {
  const { verticalPadding = CommonSize.X_SMALL } = extraData || {};

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.profile,
        classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`],
      )}
    >
      <h1 className={styles.profileTitle}>{psychic.lineName}</h1>
      <ProfileInfo psychic={psychic} psychicFrame={psychicFrame} />
      <p className={styles.profileDescription}>{psychic.usp}</p>
      <ProfileInteraction
        bootStatus={bootStatus}
        psychicFrame={psychicFrame}
        psychic={psychic}
      />
    </section>
  );
};

export default ExponeaPsychicProfile;
